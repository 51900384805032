import React from 'react'
import { Link } from 'react-router-dom'
import '../../../styles/AboutIntro.css'
import PatientsCounter from './PatientsCounter/PatientsCounter'
function AboutIntro() {
    return (
        <div className='AboutIntro'>
            <div className="container">
                <div className="row">
                    <div className="left-col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000" >
                        <h2>Elevate Your Beauty: <span>Prime Skin & Laser Clinic's</span> Expert Solutions</h2>
                        <p>Welcome to Prime Skin & Laser Clinic, where we specialize in unlocking your timeless beauty. Our clinic, equipped with state-of-the-art laser technology and a team of skilled professionals, is dedicated to providing personalized skin and hair care solutions. Experience the Prime glow as we tailor transformative treatments to enhance your natural radiance. At Prime, your beauty is our priority, and we invite you to embark on a journey of confidence and rejuvenation. Welcome to the epitome of elegance and expertise – Prime Skin & Laser Clinic.
                        </p>
                        <div className="button">
                            <Link to="/about"><span>Read More</span></Link>
                        </div>
                    </div>
                    <div className="right-col custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000" >
                        <div className="img-container">
                            <img src="img/a01-1030x518.jpg" alt="primeskin clinic building" />

                        </div>
                    </div>
                </div>
                <PatientsCounter />

            </div>
        </div>
    )
}

export default AboutIntro