import React from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import BannerData from '../Components/Helpers/BannerData'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import Face from '../Components/AreasofTreatmentPage/Face/Face'
import Body from '../Components/AreasofTreatmentPage/Body/Body'
import Hair from '../Components/AreasofTreatmentPage/Hair/Hair'
import Footer from '../Components/General_cmpnts/Footer/Footer'
import { Helmet } from 'react-helmet'

function AreasofTreatment() {
    const { AreasofTreatmentH2, AreasofTreatmentImg } = BannerData

    return (
        <div className='AreasofTreatment'>
            <Helmet>
                <title>Areas Of Treatment - Prime Skin, Hair &amp; Laser Clinic</title>
                <link rel="canonical" href="https://www.primeskinclinic.in/areas-of-treatment/"></link>
                <meta property="og:locale" content="en_GB"></meta>
                <meta property="og:type" content="article"></meta>
                <meta property="og:title" content="Areas Of Treatment - Prime Skin, Hair &amp; Laser Clinic"></meta>
                <meta property="og:url" content="https://www.primeskinclinic.in/areas-of-treatment/"></meta>
                <meta property="og:site_name" content="Prime Skin, Hair &amp; Laser Clinic"></meta>
            </Helmet>
            <Navbar />
            <BannerSection h2={AreasofTreatmentH2} imgUrl={AreasofTreatmentImg} />
            <Face />
            <Body />
            <Hair />
            <Footer />


        </div>
    )
}

export default AreasofTreatment