import React from 'react'
import '../../../styles/Hair.css'
import { Link } from 'react-router-dom'

function Hair() {
    return (
        <div className='Hair'>
            <div className="container">
                <div className="row">
                    <div className="left-col">
                        <div className="content">
                            <h2>Hair</h2>
                            <p>You may be worried about fine lines, wrinkles, sagging skin, hyperpigmentation, dark circles, acne spots, etc. Our team of medical experts are here with excellent solutions on skincare or problem areas.</p>
                            <Link to="/hair-care">Read More</Link>
                        </div>
                    </div>
                    <div className="right-col">
                        <div className="img-container">
                            <img src="https://images.unsplash.com/photo-1466592261899-a095b126a484?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="hair" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hair