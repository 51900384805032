import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useSearchParams } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import AreasofTreatment from './Pages/AreasofTreatment';
import FaceSkinClinic from './Pages/FaceSkinClinic';
import Blogs from './Pages/Blogs';
import BlogSinglePg from './Pages/BlogSinglePg';
import ScrollToTop from './Components/General_cmpnts/ScrollToTop/ScrollToTop';
import Career from './Pages/Career';
import Gallery from './Pages/Gallery';
import Contact from './Pages/Contact';
import Whatsapp from './Components/General_cmpnts/Whatsapp_btn/Whatsapp';
import BodyClinic from './Pages/BodyClinic';
import HairClinic from './Pages/HairClinic';
import PreLoader from './Components/General_cmpnts/PreLoader/PreLoader';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {

      // Simulate a delay
      await new Promise(resolve => setTimeout(resolve, 2400));

      if (document.readyState !== 'loading') {
        setLoading(false);
      }
    };

    fetchData();
  })
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Whatsapp />
        <PreLoader loading={loading} />
        <Routes>

          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/areas-of-treatment' element={<AreasofTreatment />} />
          <Route path='/face-skin-clinic' element={<FaceSkinClinic />} />
          <Route path='/body' element={<BodyClinic />} />
          <Route path='/hair-care' element={<HairClinic />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/blogs/:slug' element={<BlogSinglePg />} />
          <Route path='/careers' element={<Career />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/contact' element={<Contact />} />



        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
