import React from 'react';
import '../../../styles/HairIntro.css'

function HairIntro() {
    return (
        <div className='HairIntro'>
            <div className="container">
                <div className="row">
                    <div className="left-col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000" data-aos-offset="0">
                        <h2>Hair</h2>
                        <p>When we put your hair care “treatment” it really is just that. We take care of your hair and treat them to nutrient-rich herbal blends to bring the top of your head back to its natural best.</p>

                    </div>
                    <div className="right-col custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000" >
                        <div className="img-container">
                            <img src="img/areas_of_treatment/hair/hair_intro.jpg" alt="hair intro" />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HairIntro