import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import '../../../styles/GetInTouch.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReCAPTCHA from "react-google-recaptcha";

function GetInTouch() {
    const [verified, setVerified] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        setVerified(true);
    }


    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_01rs84r', 'template_7r96o05', form.current, 'hmIWSwimjLWnDnkhf')
            .then((result) => {
                console.log(result.text);
                console.log("message sent");
                toast.success('Message Sent! Your message has been sent successfully.');

            }, (error) => {
                console.log(error.text);
                toast.error('An error occurred. Please try again!');

            });
    };
    return (
        <div className='GetInTouch'>
            <div className="container">
                <div className="row">
                    <div className="col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000">
                        <h3>Give Us A Call</h3>
                        <div className="text">
                            <span><i className="fa-solid fa-phone"></i></span>
                            <div>
                                <a href="tel:+91 9746 949 999">
                                    <p>+91 9746 949 999</p>
                                </a>
                                <a href="tel:+91 495 237 4999">
                                    <p>+91 495 237 4999</p>
                                </a>
                            </div>
                        </div>

                        <h3>Send Us An Email</h3>
                        <div className="text">

                            <span><i className="fa-solid fa-envelope"></i></span><a href="mailto:primelaserclinic@gmail.com"><p>primelaserclinic@gmail.com</p></a>
                        </div>

                        <h3>Location</h3>
                        <div className="text">
                            <span><i className="fa-solid fa-location-dot"></i></span><p>
                                <a className='location-a' href="https://maps.google.com/maps?ll=11.28069,75.786692&z=14&t=m&hl=en&gl=IN&mapclient=embed&cid=7561395991271902387">
                                    2nd Floor, VEEPEE Space,
                                    <br />Wayanad Road, Civil station P.O,
                                    <br /> Eranhippalam, Kozhikode, Kerala <br />
                                    673020
                                </a>
                            </p>

                        </div>
                    </div>
                    <div className="col form custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000">
                        <form ref={form} onSubmit={sendEmail}>
                            <div>
                                <input type="text" id="name" name='name' placeholder='Name' />
                            </div>
                            <div>
                                <input type="email" id="email" name='email' placeholder='Email' />
                            </div>
                            <div>
                                <input type="text" id="phone" name='phone' placeholder='Phone' />
                            </div>
                            <div>
                                <textarea id="message" name='message' placeholder='Message' />
                            </div>
                            <ReCAPTCHA
                                sitekey="6LckKGUpAAAAAIUyg3ZEMnc56ri4SorYbxkbOeEc"
                                onChange={onChange}
                            />
                            <button type="submit" value="Send" disabled={!verified}><span>Submit</span></button>

                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}

export default GetInTouch