import React from 'react'
import '../../../styles/FaceIntro.css'
function FaceIntro() {
  return (
    <div className='FaceIntro'>
      <div className="container">
        <div className="row">
          <div className="left-col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000"data-aos-offset="0" >
            <h2>Face</h2>
            <p>You may be worried about fine lines, wrinkles, sagging skin, hyperpigmentation, dark circles, acne spots, etc. Our team of medical experts are here with excellent solutions on skincare or problem areas.</p>
           
          </div>
          <div className="right-col custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000" >
            <div className="img-container">
              <img src="img/areas_of_treatment/face-skin-clinic/face_intro.jpg" alt="face intro" />

            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default FaceIntro