import React from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import HairIntro from '../Components/HairclinicPage/HairIntro/HairIntro'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import BannerData from '../Components/Helpers/BannerData'
import Footer from '../Components/General_cmpnts/Footer/Footer'
import HairTreatmntList from '../Components/HairclinicPage/HairTreatmntList/HairTreatmntList'
import { Helmet } from 'react-helmet'

function HairClinic() {

    const { HairClinicH2, HairClinicImg } = BannerData
    return (
        <div className='HairClinic'>
            <Helmet>
                <title>Prime Best Hair Transplant Clinc in Calicut | Hair Care Clinic Calicut Kerala</title>
                <meta name="description" content="Prime Skin and Hair is the top Hair Transplant Clinic in Calicut, Kerala. We offer Effective Hair care Treatments by Highly Skilled and Experienced Surgeons"></meta>
                <link rel="canonical" href="https://www.primeskinclinic.in/hair-care/"></link>
                <meta property="og:locale" content="en_GB"></meta>
                <meta property="og:type" content="article"></meta>
                <meta property="og:title" content="Prime Best Hair Transplant Clinc in Calicut | Hair Care Clinic Calicut Kerala"></meta>
                <meta property="og:description" content="Prime Skin and Hair is the top Hair Transplant Clinic in Calicut, Kerala. We offer Effective Hair care Treatments by Highly Skilled and Experienced Surgeons"></meta>
                <meta property="og:url" content="https://www.primeskinclinic.in/hair-care/"></meta>
                <meta property="og:site_name" content="Prime Skin, Hair &amp; Laser Clinic"></meta>
                <meta property="og:image" content="img/DSC09976[1]-min.JPG" />

            </Helmet>
            <Navbar />
            <BannerSection h2={HairClinicH2} imgUrl={HairClinicImg} />
            <HairIntro />
            <HairTreatmntList />
            <Footer />
        </div>
    )
}

export default HairClinic