import React from 'react'
import '../../../styles/Team.css'

function Team() {
    return (
        <div className='Team'>
            <div className="container">
                <h2 data-aos="fade" data-aos-duration="1000">Our Team</h2>
                <div className="row">
                    <div className="card"data-aos="fade" data-aos-duration="1000">
                        <div className="img-container">
                            <img src="img/our-team/DR.-ROSHNI-SHAFEEQ-773x1030.jpg" alt="DR.-ROSHNI-SHAFEEQ" />
                        </div>
                        <div className="content">
                            <h3>DR. ROSHNI SHAFEEQ</h3>
                            <h4>MBBS, MD (Dermatology), DVD</h4>
                            <p>Dr. Roshni Shafeeq has been practicing Dermatology and Cosmetology since 2008. She finished her MBBS degree from Academy of Medical Science, Pariyaram. She did her MD (Dermatology and Venereology) and DVD from Calicut Medical College. After finishing MD in 2008, she has practiced In various Govt., private & teaching institutes, and has acquired vast experience in the field.

                                Currently she is Assistant Professor in Dermatology and Visiting Consultant in Malabar Hospital, Calicut. She got training in Aesthetic Dermatology and Lasers from Cutis Institute, Bangalore. She also had hands on training in Botox and Fillers.</p>
                        </div>
                    </div>
                    <div className="card"data-aos="fade" data-aos-duration="1000">
                        <div className="img-container">
                            <img src="img/our-team/IMG_20190401_190228-215x300.jpg" alt="DEEPTHI RAMAKRISHNAN" />
                        </div>
                        <div className="content">
                            <h3>DR. DEEPTHI RAMAKRISHNAN</h3>
                            <h4>Consultant Dermatologist</h4>
                            <p>Dr Deepthi Ramakrishnan has finished MBBS and DDVL from Calicut Medical College. After finishing Dermatology training in 2008, she has been practicing aesthetic dermatology in various centres and is well experienced.</p>
                        </div>
                    </div>
                    
                   
                    
                </div>
            </div>
        </div>
    )
}

export default Team