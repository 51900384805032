

const BannerData= {
    AboutH2: "About",
    AboutImg: "img/DSC09976[1]-min.jpg",
    AreasofTreatmentH2:"Areas of Treatment",
    AreasofTreatmentImg:"img/DSC09976[1]-min.jpg",
    FaceSkinClinicH2:"Face",
    FaceSkinClinicImg:"img/DSC09976[1]-min.jpg",
    BodyClinicH2:"Body",
    BodyClinicImg:"img/DSC09976[1]-min.jpg",
    HairClinicH2:"Hair",
    HairClinicImg:"img/DSC09976[1]-min.jpg",
    BlogH2:"Blogs",
    BlogImg:"img/DSC09976[1]-min.jpg",
    GalleryH2:"Gallery",
    GalleryImg:"img/DSC09976[1]-min.jpg",
    CareerH2:"Career",
    CareerImg:"img/DSC09976[1]-min.jpg",
    ContactH2:"Contact",
    ContactImg:"img/DSC09976[1]-min.jpg",
}

export default BannerData