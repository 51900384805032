import React from 'react'
import '../../../styles/CareerDetails.css';

function CareerDetails() {
  return (
    <div className='CareerDetails'>
      <div className="container">
        <div className="row">
          <div className="left-col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000" data-aos-offset="0">
            <h2>Career</h2>
            <p>

              At Prime Skin & Laser Clinic, we're dedicated to redefining skincare and beauty. We're on the lookout for individuals who share our commitment to promoting skin health and overall well-being. Embracing diversity and equal opportunities, we value expertise in skincare and laser treatments. Our operational structure ensures the right talent is in the right place, and through continuous support and training, we create an environment for every team member to thrive. Prime Skin & Laser Clinic is not just a workplace; it's a community that believes in the potential of each staff member, fostering both personal and professional success.
              <br />
              <br />

              {/* We invite you to reach out to us and share your resume at <a href="mailto:primelaserclinic@gmail.com">primelaserclinic@gmail.com</a>.We welcome your inquiries and applications. <a href="/contact">Contact Us</a> */}
            </p>

          </div>
          <div className="right-col custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000" >
            <div className="img-container">
              <img src="img/DSC09899[1]-min.jpg" alt="career details" />

            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default CareerDetails