import React from 'react'
import '../../../styles/HeroSection.css'

import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';


function HeroSection() {
    return (
        <div className='HeroSection'>
            <Swiper
                direction={'horizontal'}
                spaceBetween={0}
                centeredSlides={true}
                effect={'fade'}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 4500,
                    disableOnInteraction: true,
                }}
                modules={[Autoplay, EffectFade, Pagination]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <div className="img-container">
                        <img src="img/ebdf73a888-min.jpeg" alt="herosection 1" />
                        <div className="content">
                            <h2 >YOUR JOURNEY TO FIND THE <span className='best'>BEST IN YOU</span> STARTS WITH US.</h2>

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="img-container">
                        <img src="img/pexels-photo-3373721.webp" alt="herosection 2" />
                        <div className="content">
                            <h2 >GET READY TO <span className='look'>LOOK YOUR BEST</span> AT PRIME</h2>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="img-container">
                        <img src="img/b650c767b4-min.jpeg" alt="herosection 3" />
                        <div className="content">
                            <h2 >GET A <span className='makeover'>MAKEOVER</span> USING LASER TONING (PHOTO FACIAL) AT PRIME</h2>

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="img-container">
                        <img src="img/[Downloader.la]-65bdd79c49ac7.jpg" alt="CoolTech" />
                        <div className="content">
                            <h2 className='rose'>Sculpt Your Ideal Body with <span className='look'>CoolTech's</span> Effortless Cryolipolysis</h2>

                        </div>
                    </div>
                </SwiperSlide>
                



            </Swiper>
        </div>
    )
}

export default HeroSection