import React from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import BannerData from '../Components/Helpers/BannerData'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import BlogList from '../Components/BlogsPage/BlogList/BlogList';
import Footer from '../Components/General_cmpnts/Footer/Footer';
import { Helmet } from 'react-helmet';

function Blogs() {
    const { BlogH2, BlogImg } = BannerData;

    return (
        <div className='Blogs'>
            <Helmet>
                <title>Discover the Latest Skincare Trends and Tips | Prime Skin Clinic | Blog</title>
                <meta name="description" content="Learn about the latest skincare tips, treatments, and trends from our expert dermatologists. Get advice on how to keep your skin healthy and glowing, and discover which products are best for you."></meta>
                <link rel="canonical" href="https://www.primeskinclinic.in/blogs/"></link>
                <meta property="og:locale" content="en_GB"></meta>
                <meta property="og:type" content="article"></meta>
                <meta property="og:title" content="Discover the Latest Skincare Trends and Tips | Prime Skin Clinic | Blog"></meta>
                <meta property="og:description" content="Learn about the latest skincare tips, treatments, and trends from our expert dermatologists. Get advice on how to keep your skin healthy and glowing, and discover which products are best for you."></meta>
                <meta property="og:url" content="https://www.primeskinclinic.in/blogs/"></meta>
                <meta property="og:site_name" content="Prime Skin, Hair &amp; Laser Clinic"></meta>
                <meta property="og:image" content="img/DSC09976[1]-min.JPG" />

            </Helmet>
            <Navbar />
            <BannerSection h2={BlogH2} imgUrl={BlogImg} />
            <BlogList />
            <Footer />


        </div>
    )
}

export default Blogs