import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import { useParams } from 'react-router-dom';
import axios from 'axios';
// import ShareButton from '../Components/General_cmpnts/ShareButton/ShareButton';
import Footer from '../Components/General_cmpnts/Footer/Footer';
import Blog_HeroSection from '../Components/BlogsPage/BlogIndvl_Herosection/Blog_HeroSection';
import { wordpressUrl } from '../Components/Helpers/wordpressUrl';
import BlogDetails from '../Components/BlogsPage/BlogDetails/BlogDetails';
import ShareButton from '../Components/General_cmpnts/ShareButton/ShareButton';
import { Helmet } from 'react-helmet';
import MenuContext from '../Components/Context/menuProvider';


function BlogSinglePg() {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);
    const { setImage, handleNavbar, image, setnavbar } = useContext(MenuContext);


    useEffect(() => {
        if (window.scrollY > 20) {
            setImage('../../img/primeskin_logo.png')
        } else {
            setImage('../../img/primeskin_logo_white.png')

        }
    })

    useEffect(() => {

        axios.get(`${wordpressUrl}wp-json/wp/v2/blog?slug=${slug}`)
            .then(res => {
                if (res.data.length > 0) {
                    setBlog(res.data[0]);
                    // console.log(res.data[0])
                    // console.log(blog + 'blog array')
                } else {
                    console.log('No blog post found');
                }
            })
            .catch(error => {
                console.error('Error fetching blog post:', error);
            })

    }, [slug])
    return (
        <div className='BlogSinglePg'>
            <Helmet>
                {blog && <title>{blog && blog.title.rendered} - Prime Skin, Hair & Laser Clinic</title>}
                <link rel="canonical" href={`https://www.primeskinclinic.in/${blog && slug}`}></link>
                <meta property="og:locale" content="en_GB"></meta>
                <meta property="og:type" content="article"></meta>
                <meta property="og:title" content={`${blog && blog.title.rendered} - Prime Skin, Hair &amp; Laser Clinic`}></meta>
                <meta property="og:description" content={blog && blog.acf.meta_description}></meta>
                <meta property="og:url" content={`https://www.primeskinclinic.in/${blog && slug}`}></meta>
                <meta property="og:site_name" content="Prime Skin, Hair &amp; Laser Clinic"></meta>
                <meta property="og:image" content={`${blog && blog.acf.cover_image.link}`} />

            </Helmet>
            <Navbar />
            <Blog_HeroSection blogs={blog} />
            <BlogDetails blogs={blog} />
            <ShareButton />
            <Footer />


        </div>
    )
}

export default BlogSinglePg