import React from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import BannerData from '../Components/Helpers/BannerData'
import CareerDetails from '../Components/CareerPage/CareerDetails/CareerDetails';
import Footer from '../Components/General_cmpnts/Footer/Footer';
import { Helmet } from 'react-helmet';
import CareerForm from '../Components/CareerPage/CareerForm/CareerForm';

function Career() {
  const { CareerH2, CareerImg } = BannerData;

  return (
    <div className='Career'>
      <Helmet>
        <title>Careers - Prime Skin, Hair &amp; Laser Clinic</title>
        <link rel="canonical" href="https://www.primeskinclinic.in/careers/"></link>
        <meta property="og:locale" content="en_GB"></meta>
        <meta property="og:type" content="article"></meta>
        <meta property="og:title" content="Careers - Prime Skin, Hair &amp; Laser Clinic"></meta>
        <meta property="og:url" content="https://www.primeskinclinic.in/careers/"></meta>
        <meta property="og:site_name" content="Prime Skin, Hair &amp; Laser Clinic"></meta>
        <meta property="og:image" content="img/DSC09976[1]-min.JPG" />

      </Helmet>
      <Navbar />
      <BannerSection h2={CareerH2} imgUrl={CareerImg} />
      <CareerDetails />
      <CareerForm />
      <Footer />

    </div>
  )
}

export default Career