import React from 'react'
import '../../../styles/WhyChooseUs.css';

function WhyChooseUs() {
    return (
        <div className='WhyChooseUs'>
            <div className="container">
                <h2 data-aos="fade" data-aos-duration="1000">Why Choose Prime?</h2>
                <div className="row">
                    <div className="card" data-aos="fade-up" data-aos-duration="1000">
                        <div className="headline">
                            <i class="fa-solid fa-house-chimney-medical"></i>
                            <h3>State of Art Services</h3>

                        </div>
                        <p>
                            We provide advanced, non-surgical cosmetologic procedures including various Lasers. In addition, we provide basic and specialized medicines and cosmeceutical from our in house pharmacy.
                        </p>
                    </div>
                    <div className="card" data-aos="fade-up" data-aos-duration="1000">
                        <div className="headline">
                            <i class="fa-solid fa-circle-check"></i>
                            <h3> Quality of Care & Expertise</h3>

                        </div>
                        <p>
                            All our procedures will be performed only by fully trained doctors. Dr. Roshni Shafeeq, a senior consultant and asst. professor in aesthetic dermatology, has been practicing cosmetology since 2005. All machines we use are imported, safe and US FDA approved. We use injectables (wrinkle reduction injections, Fillers) with strongest clinical evidence globally.
                        </p>
                    </div>
                    <div className="card" data-aos="fade-up" data-aos-duration="1000">
                        <div className="headline">
                            <i class="fa-solid fa-sack-dollar"></i>
                            <h3> Affordability & Transparency</h3>

                        </div>
                        <p>
                            Before every treatment, patient will be explained in detail about the treatment procedure and cost.
                        </p>
                    </div>
                    <div className="card" data-aos="fade-up" data-aos-duration="1000">
                        <div className="headline">
                            <i class="fa-solid fa-shield-halved"></i>
                            <h3>Complete Privacy & Confidentiality</h3>

                        </div>
                        <p>
                            As per our strict privacy policy, you are best assured that your details will be kept safe and maintained confidentially. People from different streams holding a niche profile choose us because of our focus on maintaining utmost confidentiality.
                        </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default WhyChooseUs