import React from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import HeroSection from '../Components/HomePage/HeroSection/HeroSection'
import Footer from '../Components/General_cmpnts/Footer/Footer'
import AboutIntro from '../Components/HomePage/AboutIntro/AboutIntro'
import WhyChooseUs from '../Components/HomePage/WhyChooseUs/WhyChooseUs'
import TreatmentOverview from '../Components/HomePage/TreatmentOverview/TreatmentOverview'
import Testimonials from '../Components/HomePage/Testimonials/Testimonials'
import { Helmet } from 'react-helmet'

function Home() {
  return (
    <div className='Home'>
      <Helmet>
        <title>Prime Best Skin,Hair & Laser treatment Clinic in Calicut | Kerala</title>
        <meta name="description" content="Prime Skin&amp;Laser Clinic is the Best Skin Clinic in Calicut offering Skincare and Dermatology treatments with modern equipment by top skin specialists in Calicut."></meta>
        <link rel="canonical" href="https://www.primeskinclinic.in/"></link>
        <meta property="og:locale" content="en_GB"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content="Prime Best Skin,Hair &amp; Laser treatment Clinic in Calicut | Kerala"></meta>
        <meta property="og:description" content="Prime Skin&amp;Laser Clinic is the Best Skin Clinic in Calicut offering Skincare and Dermatology treatments with modern equipment by top skin specialists in Calicut."></meta>
        <meta property="og:url" content="https://www.primeskinclinic.in/"></meta>
        <meta property="og:image" content="img/pexels-photo-3373721.webp" />
        <meta property="og:site_name" content="Prime Skin, Hair &amp; Laser Clinic"></meta>

      </Helmet>
      <Navbar />
      <HeroSection />
      <AboutIntro />
      <WhyChooseUs />
      <TreatmentOverview />
      <Testimonials />
      <Footer />
    </div>
  )
}

export default Home