import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import '../../../styles/CareerForm.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReCAPTCHA from "react-google-recaptcha";

function CareerForm() {

    const [verified, setVerified] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        setVerified(true);
    }

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_01rs84r', 'template_o40zfq2', form.current, 'hmIWSwimjLWnDnkhf')
            .then((result) => {
                console.log(result.text);
                console.log("message sent");
                toast.success('Message Sent! Your Career form has been sent successfully.');

            }, (error) => {
                console.log(error.text);
                toast.error('An error occurred. Please try again!');

            });
    };
    return (
        <div className='CareerForm'>
            <div className="container">
                <div className="row card-container">
                    <form ref={form} onSubmit={sendEmail}>
                        <div className='input-row'>
                            <label for="name">Full name:</label>
                            <input type="text" id="name" name='name' />
                        </div>
                        <div className='input-row'>
                            <label for="email">Email:</label>
                            <input type="email" id="email" name='email' />
                        </div>
                        <div className='input-row'>
                            <label for="phone">Phone:</label>
                            <input type="text" id="phone" name='phone' />
                        </div>
                        <div className='input-row'>
                            <label for="career">Choose a career: </label>
                            <select id="career" name="career">
                                <option value="Dermatologist">Dermatologist</option>
                                <option value="Marketing Staffs">Marketing Staffs</option>
                                <option value="Pharmacist">Pharmacist</option>
                                <option value="Staff Nurse">Staff Nurse</option>
                                <option value="Front office / Administration">Front office / Administration</option>
                            </select>
                        </div>

                        <div className='input-row'>
                            <label for="message">Message:</label>
                            <textarea id="message" name='message' />
                        </div>
                        <ReCAPTCHA
                            sitekey="6Lc1KmUpAAAAAJEj6fk5lae9H4a0Bb_37dwQU-8E"
                            onChange={onChange}
                        />
                        <button type="submit" value="Send"disabled={!verified}><span>Submit</span></button>

                    </form>
                </div>

            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}

export default CareerForm