import React from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import BannerData from '../Components/Helpers/BannerData';
import GetInTouch from '../Components/ContactPage/GetInTouch/GetInTouch';
import Map from '../Components/ContactPage/Map/Map';
import Footer from '../Components/General_cmpnts/Footer/Footer';
import { Helmet } from 'react-helmet';

function Contact() {
    const { ContactH2, ContactImg } = BannerData;

    return (
        <div className='Contact'>
            <Helmet>
                <title>Contact - Prime Skin, Hair &amp; Laser Clinic</title>
                <meta property="og:locale" content="en_GB"></meta>
                <meta property="og:type" content="article"></meta>
                <meta property="og:title" content="Contact - Prime Skin, Hair &amp; Laser Clinic"></meta>
                <meta property="og:url" content="https://www.primeskinclinic.in/contact/"></meta>
                <meta property="og:site_name" content="Prime Skin, Hair &amp; Laser Clinic"></meta>
                <meta property="og:image" content="img/DSC09976[1]-min.JPG" />

            </Helmet>
            <Navbar />
            <BannerSection h2={ContactH2} imgUrl={ContactImg} />
            <GetInTouch />
            <Map />
            <Footer />


        </div>
    )
}

export default Contact