import React from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import BannerData from '../Components/Helpers/BannerData'
import BodyIntro from '../Components/BodyClinicPage/BodyIntro/BodyIntro'
import BodyTreatmntList from '../Components/BodyClinicPage/BodyTreatmntList/BodyTreatmntList'
import { Helmet } from 'react-helmet'
import Footer from '../Components/General_cmpnts/Footer/Footer'

function BodyClinic() {
    const { BodyClinicH2, BodyClinicImg } = BannerData
    return (
        <div className='BodyClinic'>
            <Helmet>
                <title>Body - Prime Skin, Hair &amp; Laser Clinic</title>
                <link rel="canonical" href="https://www.primeskinclinic.in/body/"></link>
                <meta property="og:locale" content="en_GB"></meta>
                <meta property="og:type" content="article"></meta>
                <meta property="og:title" content="Body - Prime Skin, Hair &amp; Laser Clinic"></meta>
                <meta property="og:url" content="https://www.primeskinclinic.in/body/"></meta>
                <meta property="og:site_name" content="Prime Skin, Hair &amp; Laser Clinic"></meta>
                <meta property="og:image" content="img/DSC09976[1]-min.JPG" />

            </Helmet>
            <Navbar />
            <BannerSection h2={BodyClinicH2} imgUrl={BodyClinicImg} />
            <BodyIntro />
            <BodyTreatmntList />
            <Footer />


        </div>
    )
}

export default BodyClinic