import React from 'react'
import '../../../styles/Blog_HersSection.css'

function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    const [month, year] = formattedDate.split(' ');
    return `${month}, ${year}`;
}
function Blog_HeroSection({ blogs }) {
    if (!blogs || blogs.length === 0) {
        return <div className="hero-section-skeleton-loading">
            <div className="row-loading">
                <div className="left-col-loading">
                    <div className="headline_loading"></div>
                    {/* <div className="headline_loading date"></div> */}
                    <div className="text-container-loading">
                        <div className="p-loading"></div>
                        <div className="p-loading"></div>
                    </div>
                </div>
                <div className="right-col-loading">
                    <div className="image-container-loading"></div>
                </div>

            </div>
        </div>;
    }
    return (
        <div className='Blog_HeroSection'>
            <div className="container">
                <div className="row">
                    <div className="left-col">
                        {blogs && blogs.title.rendered && <h1>{blogs.title.rendered}</h1>}
                        <div className="text">
                        <div className='editor-info'>
                                {blogs.acf && blogs.acf.editor_image ? (
                                    <img src={blogs.acf.editor_image.sizes.thumbnail} className='editor-dp' alt="editor dp" />
                                ) : (
                                    <img src="https://as1.ftcdn.net/v2/jpg/03/53/11/00/1000_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg" className='editor-dp' alt="default dp" />
                                )}
                                {blogs && blogs.acf.edited_by && <p className='editor'>{blogs.acf.edited_by}</p>}
                            </div>
                            {blogs && blogs.modified && <p> {formatDate(blogs.modified)}</p>}
                        </div>
                    </div>
                    <div className="right-col">
                        <div className="image-container">
                            {blogs && blogs.acf.cover_image &&
                                <img src={blogs.acf.cover_image.sizes.medium} alt={blogs.acf.name} />
                            }
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Blog_HeroSection