import React, { useState } from 'react';
import '../../../styles/HairTreatmntList.css'

function HairTreatmntList() {
    const [activeSection, setActiveSection] = useState(null);
    const toggleAccordion = (section) => {
        setActiveSection(activeSection === section ? null : section)
    }
    return (
        <div className='HairTreatmntList'>
            <div className="container">
                <div className="accordion-section"data-aos="fade-up"data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(1)}>

                        <img src="img/areas_of_treatment/hair/hair_restoration_treatment_red_bank_nj.jpg" className='dp-img' alt="hair restoration" /><p> PRP (Platelet Rich Plasma) for Hair Loss </p><span className="icon">{activeSection === 1 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 1 ? 'active' : ''}`}>
                        <p>Platelet Rich Plasma (PRP) hair restoration is a state of art, non-surgical, very natural medical procedure used for treatment of hair loss, hair thinning.</p>
                        <img src="img/areas_of_treatment/hair/hair_restoration_treatment_red_bank_nj.jpg" alt="PRP for hair loss img" />
                        <p>
                            Here, patients own blood is used. The PRP prepared fresh at the clinic using specialized centrifuge and is injected to scalp. The active, natural growth factors then promote hair growth. The result is a fuller, healthier looking hair. Since patients own blood is used, it is very safe.
                            <br></br>
                            <br></br>
                            PRP (Platelet Rich Plasma) therapy is an advanced, non-surgical, therapeutic procedure to treat hair loss conditions amongst patients with requirements of hair growth stimulation.PRP is usually combined with other medical therapy for better results. It is also injected under the facial skin, for skin rejuvenation.
                            <br></br>
                            At Prime skin and laser clinic we use GROWTH FACTOR CONCENTRATE (GFC) PRP for better results
                        </p>
                    </div>
                </div>
                <div className="accordion-section"data-aos="fade-up"data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(2)}>

                        <img src="img/areas_of_treatment/hair/hair_transplation.jpg" className='dp-img' alt="hair transplatation" /><p> Hair transplantation</p><span className="icon">{activeSection === 2 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 2 ? 'active' : ''}`}>
                        <img src="img/areas_of_treatment/hair/hair_transplation.jpg" alt="Hair Transplantation" />
                        <p>
                            Hair transplantation is a surgical technique that removes hair follicles from one part of the body, called the ‘donor site’, to a bald or balding part of the body known as the ‘recipient site’. The technique is primarily used to treat male pattern baldness. In this minimally invasive procedure, grafts containing hair follicles that are genetically resistant to balding (like the back of the head) are transplanted to the bald scalp. Hair transplantation can also be used to restore eyelashes, eyebrows, beard hair, chest hair, pubic hair and to fill in scars caused by accidents or surgery such as face-lifts and previous hair transplants.
                        </p>
                        <p>Prime  Skin&laser clinic provides the best treatment to regrow, thicken, and maintain healthy hair. We provide complete Hair Transplants and other related hair treatments like PRP and Mesotherapy. Transplant your hair from the best hair care clinic in Calicut. No pain. No scars. Get the best treatment from hair care experts.A hair transplant is a safe and non-surgical treatment done as a permanent solution for baldness. During hair transplantation, Hair roots are extracted from the donor area, ie back side of the head, and is implanted in the bald area using needles or an implanter pen. The cost of a hair transplant depends on the requirement of hair grafts, the size of the scalp, the density of implantation, etc. It varies from person to person. Our hair transplantation has 0% percent side effects.The medicinal composition is chosen by the doctor depending on the problem. And yes, the post procedures guidance will be given from the clinic by the surgeons.</p>
                    </div>
                </div>
                <div className="accordion-section"data-aos="fade-up"data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(3)}>

                        <img src="img/areas_of_treatment/hair/mesotherapy.jpeg" className='dp-img' alt="mesotherapy" /><p> Mesotherapy</p><span className="icon">{activeSection === 3 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 3 ? 'active' : ''}`}>

                        <p>
                            Mesotherapy is an alternative treatment that helps to cure baldness in both males and females by reducing hair loss, promoting hair growth and slowing male pattern baldness. It is a process which regulates the proteins, vitamins and other nutrient levels and growth factors in the scalp of the patient suffering from balding. This is done by injecting in the least painful way, nutrient boosters in the scalp with the help of an injection gun to aid in the nourishing and hydrating of the scalp. This also includes initiating cell metabolism, eliminating infections and promoting and accelerating hair growth, improving blood circulation in the scalp and strengthening and reviving the hair follicles to help create dense, voluminous, strong, long and healthy tresses. This is a comparatively faster treatment than its counterparts as it helps to gradually restore hormonal balance and neutralizes the effects of DHT hormone leading to accelerated growth of new hair strands.
                        </p>
                        <img src="img/areas_of_treatment/hair/mesotherapy.jpeg" alt="meso therapy for hair" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HairTreatmntList