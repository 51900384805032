import React, { useEffect } from 'react'
import '../../../styles/Testimonials.css'
import Flickity from 'flickity';
import 'flickity/css/flickity.css';


function Testimonials() {
    useEffect(() => {
        const flickityCarousel = new Flickity('.main-carousel', {
            initialIndex: 0,
            cellAlign: 'left',
            contain: true,
            draggable: true,
            wrapAround: true,
            freeScroll: true,
            prevNextButtons: true,
            autoPlay: true,
            selectedAttraction: 0.01,
            friction: 0.20,
            pageDots: true,

            imagesLoaded: true,
            pauseAutoPlayOnHover: false,

        });

        return () => {
            flickityCarousel.destroy();
        };
    }, []);
    return (
        <div className='Testimonials'>
            <div className="container">
                <h2 data-aos="fade" data-aos-duration="1000">Testimonials</h2>
                <div className="main-carousel">

                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>Awesome place. Superb interior and I feel it’s beyond fantastic. I’ve been here a couple of times, the new Prime Skin Clinic is immaculate and they sell the best products here. Everyone here is extremely professional. The clinic on the 2nd floor is also 5 stars</p>
                            </div>
                            <img src="img/testimonials/avatar.jpeg" alt="avatar" />
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <h3>STELLA MARY,Malaparamba, Calicut,Sep 30, 2015</h3>

                        </div>
                    </div>
                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>The staff is pleasant, the location is convenient, and there is plenty of parking.  The facility is beautiful and gives you the feeling of a fresh, clean, high end atmosphere. I had a laser hair removal, and it was the most detail oriented, comfortable treatment I have ever experienced.</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/avatar.jpeg" alt="avatar" />
                            <h3>RASHA SULTHANA,Puthiyara, Calicut
                                Sep 29, 2015</h3>

                        </div>
                    </div>
                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>Due to my fair complexion, I found laser hair removal far more effective at getting rid of my dark hair than shaving or waxing. Shaving left the root of my hair visible on skin and waxing was both painful and tedious. As more of my friends had success with laser hair removal, I thought it might be a good option for me.</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/avatar.jpeg" alt="avatar" />
                            <h3>ANUPAMA NAIR
                                East Hill, Calicut,Sep, 2015</h3>

                        </div>
                    </div>
                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>I'm soo happy with the cosmetic treatment done by dr Roshni at Prime clinic. Both the doctor and staffs are very lovely in their communication and I felt a very friendly environment there. I totally satisfied with the service and recommend this clinic✌🏽</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/niju.png" alt="avatar" />
                            <h3>Niju Nirdosh</h3>

                        </div>
                    </div>
                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>my experience at prime skin  and laser clinic and with the dermatologist  Dr.Roshini was exceptional. dr addressed my concerns and delivered noticeable improvement in my skin .I highly recommend this clinic to anyone seeking professional and reliable skincare services.</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/jeje.png" alt="avatar" />
                            <h3>jeRe Kk</h3>

                        </div>
                    </div>
                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>I had an outstanding experience at the laser clinic! Dr. Roshni and team were incredibly professional and friendly, making me feel at ease throughout the entire process.  Aftercare advice  provided was invaluable. I highly recommend this clinic to anyone seeking safe and effective laser treatments in a welcoming environment.</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/jo.png" alt="avatar" />
                            <h3>Jo Augustine</h3>

                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}

export default Testimonials