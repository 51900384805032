import React from 'react'
import '../../../styles/TreatmentOverview.css'
import { Link } from 'react-router-dom'

function TreatmentOverview() {
    return (
        <div className='TreatmentOverview'>
            <div className="container">
                <h2 data-aos="fade" data-aos-duration="1000">Treatments Overview</h2>
                <div className="row">
                    <div className="card" data-aos="fade-up" data-aos-duration="1000">
                        <div className="top">
                            <div className="headline">
                                <i class="fa-solid fa-notes-medical"></i>
                                <h3>LASER HAIR REDUCTION</h3>
                            </div>
                            <div className="content">
                                <p>Do you know this is the most trusted, most efficient and only USFDA approved treatment for permanent removal of unwanted hair?</p>
                            </div>
                        </div>

                    </div>
                    <div className="card" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                        <div className="top">
                            <div className="headline">
                                <i class="fa-solid fa-notes-medical"></i>
                                <h3>Acne Scar / Scar treatment</h3>
                            </div>
                            <div className="content">
                                <p>
                                    Acne scar treatment aims to minimize the appearance of scars left by acne, promoting smoother skin.<br></br>
                                    <br></br>
                                    <b>Types of Treatments:</b>


                                </p>
                                <ul>
                                    <li>eCO2</li>
                                    <li>MORPHEUS 8</li>
                                </ul>
                            </div>
                        </div>


                    </div>
                    <div className="card" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                        <div className="top">
                            <div className="headline">
                                <i class="fa-solid fa-notes-medical"></i>
                                <h3>Q-SWITCHED ND YAG LASER (PIGMENT REMOVAL/TATOO LASER</h3>
                            </div>
                            <div className="content">
                                <p>TRI-BEAM Laser Toning is a cosmetic treatment that is revolutionizing skin care.

                                </p>
                            </div>
                        </div>



                    </div>
                    <div className="card" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                        <div className="top">
                            <div className="headline">
                                <i class="fa-solid fa-notes-medical"></i>
                                <h3>PRP FOR HAIRFALL, FACIAL SKIN REJUVENATION (VAMPIRE/PLASMA FACIAL)</h3>
                            </div>
                            <div className="content">
                                <p>State of art, non-surgical and totally natural medical procedure used for treatment of hair loss, skin rejuvenation and many</p>
                            </div>
                        </div>



                    </div>
                    <div className="card" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
                        <div className="top">
                            <div className="headline">
                                <i class="fa-solid fa-notes-medical"></i>
                                <h3>WRINKLE REDUCTION TREATMENTS</h3>
                            </div>
                            <div className="content">
                                <p>
                                    It aims to smooth and rejuvenate the skin, utilizing methods like injectables and lasers.</p>
                                <br></br>
                                <ul>
                                    <b>Types of Treatments:</b>
                                    <li>Injections</li>
                                    <li>Energy based devices like HIFU & FORMA (Radiofrequency tightening)</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="card" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                        <div className="top">
                            <div className="headline">
                                <i class="fa-solid fa-notes-medical"></i>
                                <h3>FILLERS INJECTION</h3>
                            </div>
                            <div className="content">
                                <p>Ever wondered how your favourite celebrities change facial profile or defeat age? Revitalise the appearance by adding volume to your facial structure</p>
                            </div>
                        </div>

                    </div>
                    <div className="card" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                        <div className="top">
                            <div className="headline">
                                <i class="fa-solid fa-notes-medical"></i>
                                <h3>LASER SKIN TONING (PHOTO FACIAL)</h3>
                            </div>
                            <div className="content">
                                <p>A unique facial rejuvenation option that produces dramatic results, laser effectively reduces the appearance of wrinkles and loose skin for a smoother and more youthful</p>
                            </div>
                        </div>

                    </div>
                    <div className="card" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
                        <div className="top">
                            <div className="headline">
                                <i class="fa-solid fa-notes-medical"></i>
                                <h3>HAIR TRANSPLANTATION</h3>
                            </div>
                            <div className="content">
                                <p>Hair transplantation is a surgical technique that removes hair follicles from one part of the body, called the ‘donor site’
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="card" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                        <div className="top">
                            <div className="headline">
                                <i class="fa-solid fa-notes-medical"></i>
                                <h3>NON-SURGICAL FAT REDUCTION - COOL TECH</h3>
                            </div>
                            <div className="content">
                                <p>
                                    It involves treatments designed to reduce localized fat deposits without the need for surgery.
                                </p>
                                <p>
                                    <b>Cryolipolysis</b> is a noninvasive technology that uses extreme cold to dismantle fat cells and help reduce a fat pocket.
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="button" data-aos="fade" data-aos-duration="1000">
                    <Link to={'/areas-of-treatment'}  >Explore More Treatments</Link>

                </div>
            </div>
        </div>
    )
}

export default TreatmentOverview