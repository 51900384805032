import React, { useState } from 'react'
import '../../../styles/FaceTreatmntList.css'

function FaceTreatmntList() {
    const [activeSection, setActiveSection] = useState(null);
    const toggleAccordion = (section) => {
        setActiveSection(activeSection === section ? null : section)
    }
    return (
        <div className='FaceTreatmntList'>
            <div className="container">
                {/* note: some of accordions are in wrong order.It was changed after some updates on contents */}
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(1)}>

                        <img src="img/areas_of_treatment/face-skin-clinic/ad-btx.jpg" className='dp-img' alt="" /><p> Wrinkle Reduction Treatments</p><span className="icon">{activeSection === 1 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 1 ? 'active' : ''}`}>
                        <p>A cosmetic injection with Botulinum toxin which when injected under the skin, reduce or relaxes and smooths the look of lines and wrinkles caused by repetitive movements on the face – most commonly, between the eyebrows, corner of the eye, chin folds, necklines, and horizontal forehead creases.</p>
                        <img src="img/areas_of_treatment/face-skin-clinic/ad-btx.jpg" alt="wrinkle" />
                        <p>You may consider Wrinkle Reduction  injections if you are developing lines and wrinkles on your face due to common facial movements. Depending on genetics, lines can appear on the face as early as your late twenties to as late as your early forties.<br /><br />

                            Wrinkle Reduction  injections acts by temporarily reducing the underlying muscle contraction, thereby abolishing lines, and give a younger looking, wrinkle-free skin. The results that 11 million women and men experienced worldwide speak for its efficacy acceptance.

                            It can be repeated after 4 – 6 months.</p>
                        <h3>FAQS</h3>
                        <h4>How long will the treatment take? Will I see immediate results?</h4>
                        <p>The entire treatment takes about 15 minutes. You will start to see the effects develop over the following fortnight.</p>
                        <h4>Can I continue my day as normal after the treatment?</h4>
                        <p>Most patients continue with their normal day without anybody noticing. It is important not to massage or rub the areas after treatment so we advise you not to have a facial or do vigorous exercise. We also recommend that you remain upright for 3-4 hours after your treatment.</p>
                        <h4>How long do the results of Wrinkle Reduction  injections last?</h4>
                        <p>The muscle relaxation with Wrinkle Reduction  injections lasts for 3 to 6 months. When the effect of Cosmetic wears or diminishes, (i.e., the muscles that contribute to these facial expression lines again become active and the lines gradually reappear) treatment can again be administered.</p>
                        <h4>How does the skin appear afterWrinkle Reduction  injections?</h4>
                        <p>Immediately after treatment, the skin appears normal or there may be minimal redness and swelling at the treated sites. Occasionally, there can be mild bruising. If present, these changes resolve within a few hours to days. The skin feels normal and there is no unusual sensation.</p>
                        <h4>When can I expect to see results after treatment?</h4>
                        <p>Within 48 hours after treatment reduction in the muscle activity can be noticed. About two after treatment the maximal benefit is attained and continues for a few months.</p>
                        <h4>Are there any limitations after treatment?</h4>
                        <p>No. Immediately after the treatment you may resume all normal activities.</p>
                        <h4>Is there any discomfort with treatment?</h4>
                        <p>There is usually mild discomfort at the injection sites. This only lasts for a few seconds. Many patients choose to use a numbing cream on the skin prior to the injections, however, this is only seldom</p>
                        <h4>Are there any risks or side effects?</h4>
                        <p>Rarely. If Wrinkle Reduction  injections should affect an adjacent muscle, it too may have reduced activity. This has been reported in about one per cent of those treated and if this does develop, the most change is some eyelid drooping. However, any such unwanted muscle relaxation would also be temporary. There are no reports of any injury to the eye when Wrinkle Reduction  injections is administered to the muscles of the forehead.</p>

                    </div>
                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(3)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/acne_scar.jpg" className='dp-img' alt="acne scars" /><p>Acne Scars </p><span className="icon">{activeSection === 3 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 3 ? 'active' : ''}`}>
                        <p>Acne scars are usually the result of inflamed blemishes caused by skin pores engorged with excess oil, dead skin cells and bacteria. The pore swells, causing a break in the follicle wall. Shallow lesions are usually minor and heal quickly. But if there is a deep break in the wall of the pore, infected material can spill out into surrounding tissue, creating deeper lesions. The skin attempts to repair these lesions by forming new collagen fibers. These repairs usually aren’t as smooth and flawless as the original skin.</p>
                        <img src="img/areas_of_treatment/face-skin-clinic/acne_scar.jpg" alt="acne scars" />
                        <h3>Why treat acne scarring?</h3>
                        <p>Physical, emotional and social reasons for treating acne scars include: Improved appearance, Enhanced self-esteem, Promotion of better skin health</p>
                        <h3>Treatments</h3>
                        <p><span> MICRONEEDLING ( DERMAPEN4) & Microneedling radio frequency (MORPHEUS 8):</span> The micro needle roller is a small hand rolling device with hundreds of small titanium or surgical needles that pierce through the skin in order to promote collagen growth. Micro needle rollers are often used to improve the overall appearance associated with scars fine lines. Wrinkles and stretch marks, The popular treatment utilizing these rollers is known as collagen induction therapy or micro needling.
                            <br></br>
                            <br></br>
                            <span>MORPHEUS8</span> is a microneedling device that utilizes radiofrequency energy to remodel and contour the face and body via subdermal adipose remodeling
                            <br></br>
                            <br></br>
                            <span>DERMAPEN4</span> microneedling is an effective, popular and minimally invasive procedure that treats a wide range of skin concerns, not just lines and wrinkles, although it does reduce the signs of ageing too.
                            <br></br>
                            <br></br>
                            Unlike injectable treatments, Dermapen microneedling is an entirely natural process that stimulates the body’s own repair and renewal process to create collagen. Micro-fine needles are adjusted to different depths and speeds to create fractional puncture channels on the surface of the skin.
                        </p>
                        <p><span>Subcision:</span> Subcision is a simple and safe office surgery procedure for treatment of depressed acne scars. It can easily be combined with other treatments such as laser, dermaroller and scar revisions for maximum efficacy.</p>
                        <p><span>Fractional Co2 Laser for Scars (eCO2):</span> Fractional CO2 laser resurfacing is a very good treatment for acne scarring. The small columns made in the skin are very good at stimulating collagen production, which can help with smoothing out old acne scarring.</p>

                    </div>
                </div>


                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(4)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/laser_hair_reduction_1.jpg" className='dp-img' alt="laser hair reduction" /><p>Laser Hair Reduction</p><span className="icon">{activeSection === 4 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 4 ? 'active' : ''}`}>
                        <p>Many women have problems with unwanted facial hair, particularly in the areas of the eyebrow, upper lip, and chin

                            Hair Reduction has now become the number one solution to removing unwanted hair. Not only does it create smooth natural skin that no other Hair Reductionmethod can achieve, but it is also the safest, long-lasting, non-invasive and most effective way to create permanent hair removal.

                        </p>
                        <img src="img/areas_of_treatment/face-skin-clinic/laser_hair_reduction_1.jpg" alt="laser hair reduction" />
                        <p>
                            Laser hair reduction at Prime is the most comfortable alternative to waxing, shaving and epilation. We use Diode Laser technology, which is the gold standard. A typical sitting takes around 5 minutes and thanks to the advances in laser technology, the treatment now is painless. It is US FDA approved method of permanent Hair Reduction
                            <br></br>
                            <br></br>
                            On an average, one patient may require 7 – 8 sittings, depending up on the density of hair and area. Annually “Touch Up” session are recommended to maintain perfect results. We offer various promotional packages for each individual including lifelong packages.
                        </p>
                        <p>
                            <h3>DIODE LASER (US FDA)</h3>
                            Diode laser acts by selective photo thermolyses by targeting the hair follicle.Diode treatments are generally very safe. Our Diode Laser technology is of gold standard
                        </p>
                        <ul>
                            <p>Types of Diode laser:</p>
                            <li>TRITON DIOLAZE XL [755/810 NM]</li>
                            <li>E-LASE [808 NM]</li>
                        </ul>
                    </div>
                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(7)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/laser_skin_rejuvenation.jpg" className='dp-img' alt="laser skin " /><p>Laser Skin Rejuvenation</p><span className="icon">{activeSection === 7 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 7 ? 'active' : ''}`}>
                        <p>
                            Laser resurfacing is a procedure that uses a laser to improve the appearance of skin or treat minor facial flaws by removing layers of skin. This popular, non-invasive treatment, will leave you with a younger, healthier, more youthful appearance by removing the top layer of your skin, which mainly consist of old damaged skin cells and allowing the healthy new skin below to come to the surface, hence the name ‘resurfacing’.
                        </p>
                        <img src="img/areas_of_treatment/face-skin-clinic/laser_skin_rejuvenation.jpg" alt="laser skin rejuvenation img" />
                        <p>Laser Resurfacing can also greatly improve the appearance of any scarring that you may have by stimulating collagen production.
                            <br></br>
                            <br></br>
                            Following Skin blemishes can be made less visible.</p>
                        <ul>
                            <li>Sun damages</li>
                            <li>Birth marks</li>
                            <li>Other Pigmentation</li>
                            <li>Yellow Skin Tone (due to ageing)</li>
                        </ul>
                        <p>

                            The treatment time for Laser Resurfacing varies depending on the size of the treatable area.</p>
                    </div>
                </div>

                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(8)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/laser_skin_toning.jpg" className='dp-img' alt="Laser Skin Toning/Photo Facial/Carbon Peel" /><p>Laser Skin Toning/Photo Facial/Carbon Peel</p><span className="icon">{activeSection === 8 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 8 ? 'active' : ''}`}>
                        <p>A unique facial rejuvenation option that produces dramatic results, laser skin toning effectively reduces the appearance of wrinkles and loose skin for a smoother and more youthful appearance. The treatment is perfect for those looking for a safe, quick procedure to rejuvenate and revitalize the skin.</p>
                        <img src="img/areas_of_treatment/face-skin-clinic/laser_skin_toning.jpg" alt="Laser Skin Toning/Photo Facial/Carbon Peel image" />
                        <p>Skin toning refers to the use of Q-Switched lasers for non-ablative skin rejuvenation. In skin toning, multiple passes of low-dose Q-Switched Nd:YAG are delivered through a large spot size to induce sublethal injury to melanosomes causing fragmentation and rupture of melanin granules into the cytoplasm. This effect is highly selective for melanosomes and leads to overall skin brightness.
                            <br></br>
                            <br></br>
                            Switched laser is used for skin toning. Using a top-hat beam profile ensures control of homogeneous energy delivery and maximum safety without hot-spots nor any risk of side effects or complication for the patient.
                            <br></br>
                            <br></br>
                            Fractional co2 laser resurfacing can be done to give a youthful appearance to skin to reduce age spots and wrinkles.
                        </p>
                    </div>

                </div>

                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(10)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/anti-aging.jpg" className='dp-img' alt="Laser Skin Toning/Photo Facial/Carbon Peel" /><p>Anti ageing</p><span className="icon">{activeSection === 10 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 10 ? 'active' : ''}`}>
                        <h3>What should an anti-ageing skin care routine look like? </h3>
                        <p>
                            One should start working with the dermatologist or beauty expert to build an effective anti-aging skin care routine depending upon her own beauty goals. The skin professional will first assess your problem areas in the skin and make recommendations based on your unique needs. Your custom regimen might include following products and procedures:
                            <br></br>
                            <br></br>
                            There is a continuous loss of subcutaneous fat as the human body ages, which results in the formation of wrinkles. In addition to that, long term exposure to sun can also cause skin discoloration and development of fine lines. Eating healthily and keeping your body hydrated everyday can slow down aging effects. However, it cannot significantly reduce the physical signs of aging.here are some non surgical antiaging treatment modalities

                        </p>
                        <img src="img/areas_of_treatment/face-skin-clinic/anti-aging.jpg" alt="anti ageing" />
                        <ul>
                            <li>Wrinkle Reduction Injections Injections</li>
                            <li>HIFU (Non Surgical Face Contouring & Tightening</li>
                            <li>FORMA (Radiofrequency Skin Tightening)</li>
                            <li>Dermal Fillers</li>
                            <li>Chemical Peeling</li>
                            <li>Laser Skin Rejuvenation /Skin Resurfacing</li>
                            <li>Microdermabrasion</li>
                            <li>Botox injections</li>
                            <li>Thread lift</li>
                            <li>Vampire face lift</li>
                            <li>Platelet Rich Plasma Therapy</li>
                        </ul>
                        <p>
                            With such an extensive list of specialist treatments, it is important that you understand each one and choose the right treatment for you – and this is why we are here to help.
                            <br></br>
                            <br></br>
                            We are here to assist you on the best possible treatment plan to ensure you get the best, yet safest results possible.
                        </p>
                    </div>
                </div>

                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(11)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/pigment_removal.jpg" className='dp-img' alt="Laser Skin Toning/Photo Facial/Carbon Peel" /><p>Pigment Removal</p><span className="icon">{activeSection === 11 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 11 ? 'active' : ''}`}>
                        <p>Pigment removal can be done with various modalities like skin whitening creams, mesotherapy, chemical peeling and lasers creams and ointments- prime pharmacy has a wide array of De pigmenting creams suitable for each patient, they are used alone or in conjunction with other therapeutic modalities</p>
                        <img src="img/areas_of_treatment/face-skin-clinic/pigment_removal.jpg" alt="Pigment Removal" />
                        <p>
                            <span>Mesotherapy</span> – small intradermal injections with skin lightening agents are given on to the face in weekly intervals for a bright and glowing skin.
                            <br></br>
                            <br></br>
                            <span>Q-Switched Lasers-Qs Nd-Yag Laser</span> is used in prime clinic for various pigmentary problems on skin and body with excellent results even from the first sitting onwards in conditions like freckles etc, they are also used for tattoo removal Skin toning – this is a treatment modality which has gained popularity recently, low energy Q- switched laser treatments are given on skin for a skin brightening effect.
                        </p>
                    </div>
                </div>

                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(2)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/chemical_peeling.jpg" className='dp-img' alt="chemical peeling" /><p> Chemical Peeling </p><span className="icon">{activeSection === 2 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 2 ? 'active' : ''}`}>
                        <p>
                            A chemical peel is a technique used to improve the appearance of the skin on the face, neck or hands. A chemical solution is applied to the skin that causes it to exfoliate and eventually peel off. The new, regenerated skin is usually smoother and less wrinkled than the old skin.<br /> The new skin is also temporarily more sensitive to the sun. There are three basic types of chemical peels: – Superficial or Lunchtime Peel: Alpha-hydroxyl acid or another mild acid is used to penetrate only the outer layer of skin to gently exfoliate it. The treatment is used to improve the appearance of mild skin discoloration and rough skin as well as to refresh the face, neck, chest or hands.
                        </p>
                        <img src="img/areas_of_treatment/face-skin-clinic/chemical_peeling.jpg" alt="chemical peeling" />
                        <p><span>Medium Peel:</span> Glycolic or trichloroacetic acid is applied to penetrate the out and middle layers of skin to remove damaged skin cells. The treatment is used to improve age spots, fine lines and wrinkles, freckles and moderate skin discoloration. It also can be used to smooth rough skin and treat some precancerous skin growths, i.e. actinic keratosis.
                            <br /><br />
                            <span>Deep Peel:</span> Tricholoracetic acid or phenol is applied to deeply penetrate the middle layer of skin to remove damaged skin cells. The treatment removes moderate lines, age spots, freckles and shallow scars. Patients will see a dramatic improvement in skin appearance. The procedure is used on the face and only can be performed once.
                            <br></br>
                            <br></br>
                            We also provide Peeling before wedding: A gentle peel that will leave you with a wedding ready complexion.
                        </p>

                    </div>
                </div>



                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(9)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/Fillers.jpg" className='dp-img' alt="Laser Skin Toning/Photo Facial/Carbon Peel" /><p>Fillers</p><span className="icon">{activeSection === 9 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 9 ? 'active' : ''}`}>
                        <p>
                            When you are young, your face is plump and firm. As you mature, your face begins to lose its firmness, causing hollows, folds and wrinkles.
                            <br></br>
                            <br></br>

                            Cosmetic fillers can revitalize the appearance by adding volume to your facial structure and bring back youthfulness. Fillers are natural protein based gels containing Hyaluronic acid, a substance that we lose from our skin as we age.It is injected under the skin, and is a simple procedure in experienced hands, with immediate results. It is also called “Liquid Face Lift”.Dermal fillers we use are all fda approved like juvederm voluma volift restylane perlane etc.

                        </p>
                        <img src="img/areas_of_treatment/face-skin-clinic/Fillers.jpg" alt="dermal fillers" />
                        <p>It’s no surprise to hear that these are one our most popular facial rejuvenation treatments. They are highly effective anti-ageing treatments, with instant results and virtually no downtime.</p>
                        <h3>FAQS</h3>
                        <h4>How do I prepare for a fillers procedure?</h4>
                        <p>Your surgeon will provide thorough pre-treatment instructions and answer any questions you may have. In the weeks before your injections, you’ll be asked to refrain from taking aspirin, ibuprofen, vitamin E, and any other medications that could cause bruising. Be sure to inform your physician of any medicines you are taking. If you have a history of cold sores, your surgeon may recommend taking Valtrex as a precaution. Your doctor may ask you to stop smoking before the procedure. Regardless of the type of procedure to be performed, hydration is very important before and after treatment for safe recovery.</p>
                        <h4>How long will the treatment take? Do I need recovery time?</h4>
                        <p>The procedure takes about 15-30 minutes and the results are immediately visible.</p>
                        <h4>What are the things to be taken care of following the treatment?</h4>
                        <p>
                            A filler treatment can be very quick and effective and have a minimal impact on your time. It takes 20-40 minutes depending on the number of lines and wrinkles treated. Make-up can be applied immediately after a filler treatment and you can go straight back to your daily routine. It is common to have some redness and swelling following the treatment, although this usually resolves after a few hours and the results can be seen instantly.
                            <br></br>
                            <br></br>
                            Advise that you avoid a facial or similar treatments that may disturb the products or expose the newly-treated areas to strong sunlight for 2-3 days.
                        </p>
                        <h4>How long will fillers treatment last?</h4>
                        <p>You will probably not need to have another treatment for around 4 – 6 months. Your fully qualified Doctors will advise you to return to the clinic after a couple of weeks to see how you are getting on and whether you need to have some more filler injected to achieve the optimum result.</p>
                        <h4>What side effects should I expect?</h4>
                        <p>In the days after the treatment, there may be some redness and minimal swelling, which normally settles within 1-2 days. Very occasionally, patients may suffer some bruising at the injection site. Your doctor will arrange to see you for a review of your treatment two weeks later.</p>
                    </div>

                </div>


                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(12)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/acne_scars.jpg" className='dp-img' alt="Laser Skin Toning/Photo Facial/Carbon Peel" /><p>Acne Solutions</p><span className="icon">{activeSection === 12 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 12 ? 'active' : ''}`}>
                        <p>There is no classic acne case. Those who suffer from acne are all of different ages, different backgrounds and different lifestyles. Acne can be controlled with the right combination of products, used in the correct way.</p>
                        <img src="img/areas_of_treatment/face-skin-clinic/acne_scars.jpg" alt="Acne Solutions" />
                        <p>Acne, medically known as Acne Vulgaris, is a skin condition that involves the oil glands at the base of hair follicles. Acne is not dangerous however can leave undesirable marks and scars on your face.</p>
                        <p>Acne can present itself as common whiteheads, blackheads and papules all the way upto serious pustules, nodules and cysts. Acne is a condition which should not be ignored.</p>
                        <p>Experts at Prime have treated Acne with great success over the past years. A combination of oral and topical treatment may be prescribed depending on the degree and severity of the condition.</p>
                    </div>

                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(13)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/plasma_facial.jpg" className='dp-img' alt="Laser Skin Toning/Photo Facial/Carbon Peel" /><p>Plasma Facials</p><span className="icon">{activeSection === 13 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 13 ? 'active' : ''}`}>
                        <img src="img/areas_of_treatment/face-skin-clinic/plasma_facial.jpg" alt="Plasma Facials image" />
                        <p>PRP prepared from your own blood is mixed with hyaluronic acid and is injected on to your face either as mesothery or as multiple small intradermal injections. This is otherwise called vampire facelift or plasma facial. This gives you a younger looking smooth skin.</p>
                    </div>

                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(14)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/fractional_laser.jpg" className='dp-img' alt="fractional_laser" /><p>eCO2 -FRACTIONAL LASER FOR Acne SCAR & Skin resurfacing</p><span className="icon">{activeSection === 14 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 14 ? 'active' : ''}`}>
                        <p>The safest and most effective treatment for the majority of patients with acne scars is the eCO2 – FRACTIONAL LASER. This laser works by resurfacing only a “fraction” of the skin at a time, leaving the surrounding area intact for faster healing. The eCO2 -FRACTIONAL LASER pokes tiny microscopic holes in the skin down past the epidermis and into the dermis. These holes cause new collagen to be generated, which fills in the acne scars and creates rejuvenated smoother skin.</p>
                        <img src="img/areas_of_treatment/face-skin-clinic/fractional_laser.jpg" alt="fractional_laser image" />
                        <p>The eCO2 -FRACTIONAL LASER causes a wound in the skin, which means you will have three to four days of downtime when the skin is healing. During that time you have to apply an ointment frequently to your face keep the skin greasy, which makes healing faster. The eCO2 – FRACTIONAL LASER safely treats acne scars in patients with all skin types and tones.</p>
                    </div>

                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(15)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/YAG Laser.jpg" className='dp-img' alt="YAG Laser" /><p>Q-switched ND-YAG LASER</p><span className="icon">{activeSection === 15 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 15 ? 'active' : ''}`}>
                        <p>The Nd:YAG Laser has been the true workhorse in removing well-defined brown spots for more than a decade. There are many different types of lasers that have been developed and it is important to understand which lasers are best for which procedures. Your dermatologist will decide which laser best suits your needs. In general hair removal is best accomplished with the long pulse diode and long pulse Nd:YAG lasers and Skin pigmentation, including lentigines and tattoo removal with the Q- switched Nd:Yag laser.</p>
                        <img src="img/areas_of_treatment/face-skin-clinic/YAG Laser.jpg" alt="YAG Laser" />
                        <p>At Prime, we use Q-Switched Nd-YAG laser, a non-ablative laser with proven efficiency.</p>
                        <h3>
                            BENEFITS Q-SWITCHED ND-YAG LASER
                        </h3>
                        <ul>
                            <li>Removes brown age and liver spots (lentigines)</li>
                            <li>Removes freckles</li>
                            <li>Removes tattoos</li>
                            <li>Removes brown birthmarks and Nevus of Ota</li>
                            <li>Usually a one-time treatment</li>
                            <li>Minor irritation to skin only</li>
                            <li>Minimal risk</li>
                            <li>Stimulates new collagen synthesis to improve skin tone</li>
                        </ul>
                        <p>The Q-Switched Nd:YAG laser delivers light quickly in billionths of seconds and a very high energy. These pulses are absorbed by the tattoo, breaking it into pigment particles small enough for removal by the body. Q-Switched laser technology offers high efficiency at very low risk. It enables successful tattoo removal with little chance of side effects.</p>

                    </div>

                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(16)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/mesotherapy.jpg" className='dp-img' alt="mesotherapy" /><p>Mesotherapy</p><span className="icon">{activeSection === 16 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>
                    </button>
                    <div className={`panel ${activeSection == 16 ? 'active' : ''}`}>
                        <img src="img/areas_of_treatment/face-skin-clinic/mesotherapy.jpg" alt="mesotherapy" />
                        <p>
                            Mesotherapy is a technique that uses injections of vitamins, enzymes, hormones, and plant extracts to rejuvenate and tighten skin, as well as remove excess fat.
                            <br></br>
                            <br></br>
                            Michel Pistor, a doctor in France, developed the technique in 1952. It was originally used to relieve pain. In the years since, it has gained popularity in the United States and other parts of the world.
                        </p>
                        <p>Today, mesotherapy is used to:</p>
                        <ul>
                            <li>remove fat in areas like the stomach, thighs, buttocks, hips, legs, arms, and face</li>
                            <li>reduce cellulite</li>
                            <li>fade wrinkles and lines</li>
                            <li>tighten loose skin</li>
                            <li>recontour the body</li>
                            <li>lighten pigmented skin</li>
                            <li>treat alopecia, a condition that causes hair loss</li>
                        </ul>
                        <p>
                            The technique uses very fine needles to deliver a series of injections into the middle layer (mesoderm) of skin. The idea behind mesotherapy is that it corrects underlying issues like poor circulation and inflammation that cause skin damage.
                            <br></br>
                            <br></br>
                            There isn’t a standard formula for the substances injected in mesotherapy. Doctors use many different solutions, including:
                        </p>
                        <ul>
                            <li>prescription medicines like vasodilators and antibiotics</li>
                            <li>hormones such as calcitonin and thyroxin</li>
                            <li>enzymes like collagenase and hyaluronidase</li>
                            <li>herbal extracts</li>
                            <li>vitamins and minerals</li>
                        </ul>

                        <p>
                            <br></br>
                            <span>DERMAPEN4</span> microneedling is an effective, popular and minimally invasive procedure that treats a wide range of skin concerns, not just lines and wrinkles, although it does reduce the signs of ageing too.
                            The latest Dermapen is taking the microneedling pen industry by storm.
                            <br></br>
                            <br></br>
                            Dermapen 4 glides over the skin creating millions of fine, vertical fractional channels up to 104% faster than other microneedling pens. These channels can carry up to 80% more topical nutrients deeper into the skin.
                            <br></br>
                            <br></br>
                            The micro-injuries trigger a natural process of repair, Increasing the production of collagen and elastin and visibly improving a wide range of skin concerns
                        </p>

                    </div>

                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(17)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/Radio frequency Skin Tightening.jpg" className='dp-img' alt="Laser Skin Toning/Photo Facial/Carbon Peel" /><p>Forma Treatment (Radio frequency Skin Tightening)</p><span className="icon">{activeSection === 17 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>
                    </button>
                    <div className={`panel ${activeSection == 17 ? 'active' : ''}`}>
                        <img src="img/areas_of_treatment/face-skin-clinic/Radio frequency Skin Tightening.jpg" alt="Radio frequency Skin Tightening" />
                        <p>Are you looking for the best way to tone and tighten your skin without any downtime? Our skin tightening treatment offers collagen stimulation without any invasive procedures—and you’ll feel a difference after the first or second treatment!
                            For face, eyes and neck skin tightening and facial sculpting, Forma is the only auto-adjusting thermal radio frequency skin treatment. The <span>Forma</span> device uses radio frequency technology to target the deep layers of the skin. The non-invasive subdermal heating stimulates collagen and improves the skin’s elasticity, resulting in tightened skin and decreased wrinkles.</p>
                        <h3>GREAT FOR:</h3>
                        <ul>
                            <li>FINE LINES AND WRINKLES</li>
                            <li>LOOSE SKIN</li>
                        </ul>
                    </div>

                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(18)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/medi-facials.jpg" className='dp-img' alt="Laser Skin Toning/Photo Facial/Carbon Peel" /><p>Medi-Facials</p><span className="icon">{activeSection === 18 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>
                    </button>
                    <div className={`panel ${activeSection == 18 ? 'active' : ''}`}>

                        <h3>What is a Medi-facials?</h3>
                        <p>
                            Medi-facials are medical-grade facials. These treatments are designed to treat specific skin conditions or disorders and are safe for almost all skin types. Medi-facials are done at a certified and experienced dermatologist’s clinic using many devices and techniques that include microcurrents, dermaplaning, photo rejuvenation, LED therapy, peels, microdermabrasion, and more.
                            <br></br>
                            <br></br>
                            They can help to treat a range of issues like dull, dry, and loose skin. They can repair the skin and restore a youthful, glowing, and hydrated look. They can be tailored to resolve sun damage, pigmentation issues, and signs of aging.
                        </p>
                        <h3>Benefits of a medi-facial</h3>
                        <p>The benefits of a medi-facial are aplenty. Usually, spa facials do not penetrate the skin deeply for an effective result. They are superficial. In medi-facials, devices like ultrasonics and mesoporation can be used in combination with intraceuticals like vitamins, serums, chemical peels, and plant extracts to give promising and long-lasting results.
                            <br></br>
                            <br></br>
                            Within 48-72 hours, you can get healthy and hydrated skin. Within 28 to 48 days you can get long-term benefits due to cell regeneration. Other benefits of a medi-facial are:</p>
                        <ul>
                            <li>Tighter skin</li>
                            <li>Moisturized skin</li>
                            <li>Relaxed mood and decreased stress</li>
                            <li>Exfoliated, soft, and supple skin</li>
                            <li>Anti-inflammatory and anti-bacterial benefits</li>
                            <li>Reduction in aging signs</li>
                            <li>Smoothened skin texture</li>
                            <li>No downtime required</li>
                            <li>Painless and non-invasive</li>
                        </ul>
                        <h3>Ideal candidates for this facial</h3>
                        <p>Almost anyone can get a medi-facial. You are an ideal candidate for this treatment if you suffer from any other above-mentioned skin conditions. However, you must not have any active skin conditions that could hamper the effectiveness of a medi-facial. At Prime skin and laser clinic, we make a thorough assessment of your medical history, current condition, skin health, and skin goals before recommending a particular type of medi-facial for you.
                            <br></br>
                            <br></br>
                            We like to inform our patients about a few important things to help them make a well-informed decision. These are:
                        </p>
                        <ul>
                            <li>Each medi-facial is custom-tailored to meet certain skin needs</li>
                            <li>Cleansing, exfoliation, and extractions happen on a more intense level than regular facials</li>
                            <li>Medi-facials are safe and effective</li>
                            <li>Medi-facials can complement other treatments like laser resurfacing and derma fillers</li>
                            <li>Post-treatment, you can wear make-up; however, you must avoid doing so at least until the next day</li>
                            <li>Medi-facials do not involve any downtime </li>
                            <li>Post-treatment, you can expect some redness, which subsides within a few hours</li>
                            <li>Medi-facials are usually safe for pregnant women; however, certain techniques like microdermabrasion used during a medi-facial may not be safe for an expecting mother</li>

                        </ul>
                        <p>If you require a local anesthetic, our doctors will help administer the same</p>
                        <h3>TYPES OF MEDIFACIALS AT PRIME SKIN CLINIC</h3>
                        <h4>1)MesoJet Facial</h4>
                        <p>
                            Mesojet is a high-quality treatment using innovative technology to cleanse, exfoliate and hydrate the skin.
                            Mesojet is a multistep treatment that uses moisture to cleanse, exfoliate and treat the skin. During the procedure dead skin is removed which allow the right nutrients to penetrate deeply into the skin. Unlike other treatments mesojet is a key tool in rejuvenating, moisturizing method for a beautiful skin. It can be performed weekly and or alternatively two weeks with a conjoined treatment suggested by one of our specialist to achieve a much more glowing and healthy skin.
                            <br></br>
                            <br></br>
                            MesoJet is the result of our long lasting experience with the main hydroporation technologies on the
                            market and as producers of professional cosmetic serums devoted to hydrodynamic procedures.
                            MesoJet is engineered to provide immediate and long lasting medical aesthetic results through
                            procedures that are 100% safe and extremely enjoyable at the same time.
                            Specific products and programs allow to customize and control each procedure at all stages, offering
                            always a pampering treatment experience.To know more about it, please read the brochure below.
                        </p>
                        <h4>2)Plasma Facial PRP ( PLATELET RICH PLASMA) or Vampire Facial</h4>
                        <p>Plasma Facial PRP ( PLATELET RICH PLASMA) or Vampire facial is a facial rejuvenation treatment that uses your own plasma to stimulate collagen production and cell migration, allowing for the elimination of lines, folds, and wrinkles. Results from PRP therapy are natural looking and subtle, and they can last for as long as two years.</p>
                        <h4>3)Aqua Facial</h4>
                        <p>Aqua facial is an advanced form of Hydra facial that promises to nourish, restore, and prevents damage of your skin. It is popular among celebrities as it gives extra-ordinary results. The procedure of Aqua facial is non-invasive and utilizes hydro-technology.</p>
                        <h4>4)Prime Pumpkin Facial</h4>
                        <p>For deep moisturizing and advanced exfoliation, this seven-step medi-facial is the key. After the face is cleansed with a Vitamin A and C cleanser, crystal-free hydradermabrasion exfoliates to remove dead skin cells and bathe the skin gently in water. A natural pumpkin peel is then applied to improve the overall clarity, texture, and smoothness. A green tea neutralizer is applied followed by a pumpkin activator and cellular matrix C. Post this, a dry collagen mask is applied and cleansed with warm water, and then a biopeptide rejuvenation serum is applied. Finally, for a fantastic glow, we apply an omega-3 marine mask. We recommend this as a natural and renewing treatment.</p>
                        <h4>5)Prime Hollywood Laser Peel/ Carbon Peel</h4>
                        <p>Also known as the carbon peel, this medi-facial heals damaged skin for a quick, refreshing look., it can even the skin tone, reduce pigmentation, decrease fine lines and scars, and give you a younger appearance. After a Vitamin C rich cleanser and crystal-free hydradermabrasion, a carbon peel is applied evenly on the face. Following this, we apply a Q-switched laser to heat the carbon and disperse it to rejuvenate the skin and improve clarity and brightness.. Lastly, a natural brightening and hydrating mask containing active ingredients from sea plants, seaweeds, and spirulina is applied to brighten the face.</p>
                        <h4>6)BB Glow Facial</h4>
                        <p>
                            The BB Glow treatment is the latest and fastest-growing treatmentThis treatment is a semi-permanent foundation for your skin. It will even out imperfections and discoloration, leaving your skin looking like you’re wearing the highest quality, lightweight face makeup.
                            <br></br>
                            <br></br>
                            The BB Glow serum is a scientifically proven and tested skin care product that provides coverage for facial imperfections including hyperpigmentation, redness, dark circles, and large pores. The premium BB Glow ampoule serum with whitening and skin rejuvenating properties will help alleviate major skin problems without overstimulating the skin. The color component of the BB glow formula brightens the skin and gives it a soft, glossy look with ingredients including niacinamide and peptides. This will give you a natural-looking, waterproof base that will NOT rub off!
                        </p>
                    </div>

                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(5)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/Microdermabrasion.jpg" className='dp-img' alt="microdermabrasion" /><p>Microdermabrasion</p><span className="icon">{activeSection === 5 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 5 ? 'active' : ''}`}>
                        <p>Do you ever look in the mirror and wish that your face had a more even skin tone? If you answered yes, you may want to consider microdermabrasion.
                            <br></br>
                            <br></br>
                            Microdermabrasion is a noninvasive, skin resurfacing procedure that gently exfoliates or removes the superficial layer of dry, dead skin cells to reveal healthy new skin that looks and feels smoother. Fine lines, blemishes, dull skin and sun spots can be improved, especially with regular treatments.
                        </p>
                        <img src="img/areas_of_treatment/face-skin-clinic/Microdermabrasion.jpg" alt="microdermabrasion image" />
                        <p>This is a painless, low-risk skin treatment that can be added to your beauty routine.</p>
                        <h3>When to consider microdermabrasion?</h3>
                        <ul>
                            <li>To brighten your skin and have a healthy glow.
                            </li>
                            <li>When you have sun damage, acne scars, or fine lines.</li>
                            <li>When your pores are clogged and you often have blackheads and/or whiteheads.</li>
                            <li>To maintain healthy, clear skin.</li>
                        </ul>
                        <p>

                            Microdermabrasion appeals to many patients. They like that they can see more youthful skin without having downtime.</p>
                    </div>
                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(6)}>
                        <img src="img/areas_of_treatment/face-skin-clinic/walt_mole_removal.jpg" className='dp-img' alt="walt mole removal" /><p>Wart / Mole Removal by Radio Frequency</p><span className="icon">{activeSection === 6 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>
                    <div className={`panel ${activeSection == 6 ? 'active' : ''}`}>
                        <p>You may feel concerned or embarrassed about the appearance of Warts or Moles. You simply find them unwelcome or they may have appeared in a place that causes you some discomfort.</p>
                        <img src="img/areas_of_treatment/face-skin-clinic/walt_mole_removal.jpg" alt="Facial Wart Removal img" />
                        <p>You might feel it is a cosmetic issue and you might feel self-conscious by their appearance particularly if they are on your face. Radio frequency ablation removes small warts and moles on face without damaging the skin.
                            <br></br>
                            <br></br>
                            Excess fatty deposits in the chin, neck and cheeks may give the appearance of being overweight, or make the neck look like it hangs or cheeks look like they droop. This unwanted fat may be gently removed by various mini procedures.
                        </p>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default FaceTreatmntList