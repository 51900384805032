import React from 'react'
import '../../../styles/Map.css'

function Map() {
  return (
    <div className='Map'>
      <div className="container">

        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15650.950376884879!2d75.7866915!3d11.2806896!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65eb9d51fd4b1%3A0x68ef799791d30cb3!2sPRIME%20Skin%2C%20Hair%20%26%20Laser%20clinic!5e0!3m2!1sen!2sin!4v1703145351266!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>

    
  )
}

export default Map