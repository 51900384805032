import React, { useState } from 'react';
import '../../../styles/BodyTreatmntList.css'

function BodyTreatmntList() {
    const [activeSection, setActiveSection] = useState(null);
    const toggleAccordion = (section) => {
        setActiveSection(activeSection === section ? null : section)
    }
    return (
        <div className='BodyTreatmntList'>
            <div className="container">
                <div className="accordion-section"data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(1)}>

                        <img src="img/areas_of_treatment/body/laser_hair_reduction.jpg" className='dp-img' alt="laser hair reduction" /><p> Laser hair Reduction  </p><span className="icon">{activeSection === 1 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 1 ? 'active' : ''}`}>
                        <img src="img/areas_of_treatment/body/laser_hair_reduction.jpg" alt="laser hair reduction" />

                        <p>
                            Laser hair reduction at Prime is the most effective and comfortable alternative to waxing, shaving and epilation. It works on even the darkest and coarsest body hair and the results are long lasting.
                            <br></br>
                            <br></br>
                            We use Diode Laser technology, which is the gold standard. A typical sitting takes around 5 minutes and thanks to the advances in laser technology, the treatment now is painless.
                            <br></br>
                            <br></br>
                            It is the fastest and most effective way to remove areas of unwanted hair and only US FDA approved method of permanent hair removal.
                            <br></br>
                            <br></br>
                            On an average, one patient may require 7 – 8 sittings, depending up on the density of hair and area. Annually “Touch Up” session are recommended to maintain perfect results. We offer various promotional packages for each individual including lifelong packages.

                        </p>
                    </div>
                </div>
                <div className="accordion-section"data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(4)}>

                        <img src="img/our_technology/CoolTech.jpg" className='dp-img' alt="cooltech" /><p>Non Surgical Fat Reduction - CoolTech</p><span className="icon">{activeSection === 4 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 4 ? 'active' : ''}`}>
                        <p>
                            Cooltech uses cryolipolysis which is controlled cooling technology to safely and effectively freeze unwanted fat cells. A vacuum applicator is applied to the area which holds it between cooling panels at a set temperature for up to 70 minutes. This triggers a process called Apoptosis and your body takes care of it from there. In the months following your treatment those unwanted fat cells are slowly absorbed and naturally processed through the lymphatic system.  As this process takes a few months to reach completion the skin in the area will tighten like it would if you were to lose weight naturally. Therefore, there is far less risk of loose skin than there is with liposuction.
                            <br></br>
                            <br></br>
                            Adipolisys assisted by freezing cooltech is a new non-invasive treatment. A real alternative to liposuction for fat reduction.
                            When fat cells are exposed to precise cooling, they initiate a
                            process of natural removal (Apoptosis).
                            The fat cells in the treated area are gradually eliminated through
                            the normal metabolic process.The adipocytes are more susceptible to cold than other tissues
                            such as muscle, nerve, and skin.

                            <a class="resume" href="files/Cooltech.pdf" target="_blank" download><i
                                class="fa-solid fa-download"></i>Download Brochure</a>
                        </p>
                    </div>
                </div>
                <div className="accordion-section"data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(2)}>

                        <img src="img/areas_of_treatment/body/stretch_mark.jpg" className='dp-img' alt="strech marks" /><p>Stretch Mark Reduction </p><span className="icon">{activeSection === 2 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 2 ? 'active' : ''}`}>
                        <p>
                            Stretch marks are very common and delicate to treat. They are especially troublesome for after pregnancy. They can occur anywhere on the body where the skin has been stretched, especially the abdomen, breasts, thighs and buttocks.
                        </p>
                        <img src="img/areas_of_treatment/body/stretch_mark.jpg" alt="strech marks" />
                        <p>Whatever the size of your stretch marks, at Prime, we have treatment solutions that may bring you relief.
                            <br></br>
                            <br></br>
                            Prime stretch marks reduction treatments combine effective Microneedling radio frequency / fractional co2 with platelet rich plasma therapy.  It works deep within the skin cells, encouraging skin rejuvenation and naturally promotes skin renewal.
                            <br></br>
                            <br></br>
                            Stretch mark removal usually will take approximately 4 – 6 treatments in 3 – 4-week intervals.</p>
                    </div>
                </div>
                <div className="accordion-section"data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(3)}>

                        <img src="img/areas_of_treatment/body/tag_removal.jpg" className='dp-img' alt="" /><p>Skin Tag Removal </p><span className="icon">{activeSection === 3 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 3 ? 'active' : ''}`}>
                        <p>
                            As skin tags are usually small, harmless, people tend to have them removed for aesthetic or cosmetic reasons. They most often appear on the neck, armpits, upper trunk, and body folds.
                        </p>
                        <img src="img/areas_of_treatment/body/tag_removal.jpg" alt="Laser-Stretch-Mark-Removal" />
                        <p>
                            Sometimes large ones, especially in areas where they may rub against something, such as clothing, jewelry or skin, may be removed because the area becomes frequently irritated. An individual may have a large skin tag removed from his face or under her arms in order to make shaving easier.
                            <br></br>
                            <br></br>
                            <ul>
                                <li>Cauterization – the skin tag is burned off using electrolysis (heat is used to remove them)</li>
                                <li>Cryosurgery – the skin tag is frozen off using a probe containing liquid nitrogen</li>
                                <li>Ligation – the blood supply to the skin tag is interrupted</li>
                                <li>Excision – the skin tag is removed with a scalpel (it is cut).</li>
                            </ul>
                        </p>
                    </div>
                </div>

                <div className="accordion-section"data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(5)}>

                        <img src="img/areas_of_treatment/body/skin_biopsy.jpg" className='dp-img' alt="Skin biopsy " /><p>Skin Biopsy</p><span className="icon">{activeSection === 5 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 5 ? 'active' : ''}`}>
                        <p>
                            Skin biopsy is the most important diagnostic test for skin disorders. A properly performed skin biopsy almost always yields useful diagnostic information.
                            <br></br>
                            <br></br>
                            Skin biopsy is performed to remove a lesion or to take a diagnostic sample of a skin rash or lesion, with subsequent examination of tissue using a variety of possible staining and microscopic techniques.
                        </p>
                        <img src="img/areas_of_treatment/body/skin_biopsy.jpg" alt="skin_cancer_treatment" />
                        <p>
                            The techniques most often employed are:
                            <br></br>
                            <br></br>
                            <span>Shave biopsy:</span> a tool similar to a razor to remove a small section of the top layers of skin.  It involves removal of protruding, superficial skin blemishes (for example, skin tags).
                            <br></br>
                            <br></br>
                            <span>Excision biopsy:</span> Skin is removed using scalpel incision. This may involve taking part of the skin or removing a complete skin lesion.
                            <br></br>
                            <br></br>
                            <span>Punch biopsy:</span> Here a small disc of full thickness skin is taken using a special punch biopsy instrument.
                        </p>

                    </div>
                </div>
                <div className="accordion-section"data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(6)}>

                        <img src="img/areas_of_treatment/body/tatoo_removal.jpeg" className='dp-img' alt="Skin biopsy " /><p>Laser Tatoo Removal</p><span className="icon">{activeSection === 6 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 6 ? 'active' : ''}`}>
                        <p>
                            Tattoos are created by injecting coloured pigment into small deep holes, which are very difficult to remove.
                            <br></br>
                            <br></br>
                            Laser tattoo removal is a noninvasive procedure that uses a Q-switched laser device to break down the pigment colors of an unwanted tattoo, while causing minimal damage to surrounding arrea.


                        </p>
                        <img src="img/areas_of_treatment/body/tatoo_removal.jpeg" alt="laser tatoo removal" />
                        <p>
                            Tattoo ink absorb short pulse duration/high peak laser energy so quickly that they create a photo- acoustic effect within the pigment clusters. This effect breaks down the ink into much smaller particles that can be more easily removed by the body’s natural filtering system.
                            <br></br>
                            <br></br>
                            During treatment, pulses of light from the laser are directed onto the tattoo, breaking up the tattoo pigment. After the treatment you may noticed redness and swelling, which may take a few days to settle. Any discomfort is minimal and you will be provided with aftercare products to speed up skin healing and to protect the skin.
                        </p>
                    </div>
                </div>
                <div className="accordion-section"data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(7)}>

                        <img src="img/areas_of_treatment/body/scar_removal.jpg" className='dp-img' alt="Skin biopsy " /><p>Scar Reduction</p><span className="icon">{activeSection === 7 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 7 ? 'active' : ''}`}>
                        <p>Many of us have a scar that we would like removed. Scarring can result from virtually anything that damages the skin, from chicken pox to sports injuries and surgery.</p>
                        <img src="img/areas_of_treatment/body/scar_removal.jpg" alt="laser tatoo removal" />
                        <p>Most scars can be significantly improved in appearance through one or more cosmetic techniques, including microdermabrasion, chemical peels, collagen injections, and fat transfer procedures. Microdermabrasion and chemicals peels are ablative procedures that involve the removal of regeneration of the top layer of skin.

                            Prime is pleased to offer a Laser treatment for scars, an excellent option for minimizing the appearance of scars caused by acne, surgery, or other factors. Fractional CO2 Laser can eliminate or diminish the appearance of most wrinkles, scars, warts and birthmarks. It is generally safe, performed under local anesthesia.</p>
                    </div>
                </div>
                <div className="accordion-section"data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(8)}>

                        <img src="img/areas_of_treatment/body/cryotherapy.jpg" className='dp-img' alt="Cryotherapy " /><p>Cryotherapy & Electro Cautery</p><span className="icon">{activeSection === 8 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 8 ? 'active' : ''}`}>
                        <p>Cryotherapy is a method of super freezing tissue in order to destroy it. It is done using a swab that has been dipped into liquid nitrogen or a probe that has liquid nitrogen flowing through it. Cryotherapy is mainly used to:</p>
                        <img src="img/areas_of_treatment/body/cryotherapy.jpg" alt="Cryotherapy" />
                        <p>
                            -Remove warts
                            <br></br>
                            <br></br>
                            -Destroy precancerous skin lesions (actinic keratosis or solar keratosis)
                            <br></br>
                            <br></br>
                            Electro cautery is a technique frequently used in the treatment of genital warts or warts on other areas of the body. It is the process of destroying tissue with electricity and is widely used in modern surgery. The procedure is frequently used to stop bleeding of small vessels or for cutting through soft tissue.
                        </p>
                    </div>
                </div>
                <div className="accordion-section"data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(9)}>

                        <img src="img/areas_of_treatment/face-skin-clinic/mesotherapy.jpg" className='dp-img' alt="Cryotherapy " /><p>Mesotherapy</p><span className="icon">{activeSection === 9 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 9 ? 'active' : ''}`}>
                        <p>The injection treatment, called mesotherapy, is a non surgical cosmetic solution aimed diminishing problem areas in your body such as skin disorders, reduce cellulite, excess weight, vascular disease, sports related trauma, rheumatoid conditions and face/neck rejuvenation, just to name a few.
                            <br></br>
                            <br></br>
                            Mesotherapy is a process of injecting natural ingredients such as vitamins, minerals, amino acids, and homeopathic substances, or medications that can address the various contributing causes and thereby reduce cellulite. The series of tiny injections are placed intracutaneously, just under the surface of the skin.
                            <br></br>
                            <br></br>
                            <span>DERMAPEN4</span> microneedling is an effective, popular and minimally invasive procedure that treats a wide range of skin concerns, not just lines and wrinkles, although it does reduce the signs of ageing too.
                            The latest Dermapen is taking the microneedling pen industry by storm.
                            <br></br>
                            <br></br>
                            Dermapen 4 glides over the skin creating millions of fine, vertical fractional channels up to 104% faster than other microneedling pens. These channels can carry up to 80% more topical nutrients deeper into the skin.
                            <br></br>
                            <br></br>
                            The micro-injuries trigger a natural process of repair, Increasing the production of collagen and elastin and visibly improving a wide range of skin concerns

                        </p>


                        <img src="img/areas_of_treatment/face-skin-clinic/mesotherapy.jpg" alt="mesotherapy" />
                        <ul>
                            <li> It is introduced into the mesoderm, the layer of fat and tissue underneath the skin.
                            </li>
                            <li>
                                The content mixture of the injection varies in accordance with each unique case and specific area to be treated.

                            </li>
                            <li>
                                Mesotherapy can also assist in reducing pain, and in replenishing hair loss in both men and women.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="accordion-section"data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(10)}>

                        <img src="img/areas_of_treatment/face-skin-clinic/Radio frequency Skin Tightening.jpg" className='dp-img' alt="Cryotherapy " /><p>Forma Treatment (Radio frequency Skin Tightening)</p><span className="icon">{activeSection === 10 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 10 ? 'active' : ''}`}>
                        <img src="img/areas_of_treatment/face-skin-clinic/Radio frequency Skin Tightening.jpg" alt="Radio frequency Skin Tightening" />
                        <p>Are you looking for the best way to tone and tighten your skin without any downtime? Our skin tightening treatment offers collagen stimulation without any invasive procedures—and you’ll feel a difference after the first or second treatment!
                            For face, eyes and neck skin tightening and facial sculpting, Forma is the only auto-adjusting thermal radio frequency skin treatment. The <span>Forma</span> device uses radio frequency technology to target the deep layers of the skin. The non-invasive subdermal heating stimulates collagen and improves the skin’s elasticity, resulting in tightened skin and decreased wrinkles.</p>
                        <h3>GREAT FOR:</h3>
                        <ul>
                            <li>FINE LINES AND WRINKLES</li>
                            <li>LOOSE SKIN</li>
                        </ul>
                    </div>
                </div>
                <div className="accordion-section"data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(11)}>

                        <img src="img/areas_of_treatment/body/body-skin-tightening.jpg" className='dp-img' alt="Cryotherapy " /><p>Body Fillers and Skin Tightening</p><span className="icon">{activeSection === 11 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 11 ? 'active' : ''}`}>
                        <p>
                            When you are young, your face is plump and firm. As you mature, your face begins to lose its firmness, causing hollows, folds and wrinkles.
                            <br></br>
                            <br></br>
                            Cosmetic fillers can revitalize the appearance by adding volume to your facial structure and bring back youthfulness. Fillers are natural protein based gels containing Hyaluronic acid, a substance that we lose from our skin as we age.It is injected under the skin, and is a simple procedure in experienced hands, with immediate results. It is also called “Liquid Face Lift”.Dermal fillers we use are all fda approved like juvederm voluma volift restylane perlane etc.
                        </p>
                        <img src="img/areas_of_treatment/body/body-skin-tightening.jpg" alt="Body Fillers" />
                        <p>It’s no surprise to hear that these are one our most popular facial rejuvenation treatments. They are highly effective anti-ageing treatments, with instant results and virtually no downtime.</p>
                        <h3>FAQS</h3>
                        <h4>How do I prepare for a fillers procedure?</h4>
                        <p>Your surgeon will provide thorough pre-treatment instructions and answer any questions you may have. In the weeks before your injections, you’ll be asked to refrain from taking aspirin, ibuprofen, vitamin E, and any other medications that could cause bruising. Be sure to inform your physician of any medicines you are taking. If you have a history of cold sores, your surgeon may recommend taking Valtrex as a precaution. Your doctor may ask you to stop smoking before the procedure. Regardless of the type of procedure to be performed, hydration is very important before and after treatment for safe recovery.</p>
                        <h4>How long will the treatment take? Do I need recovery time?</h4>
                        <p>The procedure takes about 15-30 minutes and the results are immediately visible.
                        </p>
                        <h4>What are the things to be taken care of following the treatment?</h4>
                        <p>A filler treatment can be very quick and effective and have a minimal impact on your time. It takes 20-40 minutes depending on the number of lines and wrinkles treated. Make-up can be applied immediately after a filler treatment and you can go straight back to your daily routine. It is common to have some redness and swelling following the treatment, although this usually resolves after a few hours and the results can be seen instantly.

                            Advise that you avoid a facial or similar treatments that may disturb the products or expose the newly-treated areas to strong sunlight for 2-3 days.</p>
                        <h4>How long will fillers treatment last?</h4>
                        <p>You will probably not need to have another treatment for around 4 – 6 months. Your fully qualified Doctors will advise you to return to the clinic after a couple of weeks to see how you are getting on and whether you need to have some more filler injected to achieve the optimum result.</p>
                        <h4>What side effects should I expect?</h4>
                        <p>In the days after the treatment, there may be some redness and minimal swelling, which normally settles within 1-2 days. Very occasionally, patients may suffer some bruising at the injection site. Your doctor will arrange to see you for a review of your treatment two weeks later.</p>
                    </div>
                </div>
                <div className="accordion-section"data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(12)}>

                        <img src="img/areas_of_treatment/face-skin-clinic/chemical_peeling.jpg" className='dp-img' alt="Cryotherapy " /><p>Chemical Peeling</p><span className="icon">{activeSection === 12 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 12 ? 'active' : ''}`}>
                        <p>
                            A chemical peel is a technique used to improve the appearance of the skin on the face, neck or hands. A chemical solution is applied to the skin that causes it to exfoliate and eventually peel off. The new, regenerated skin is usually smoother and less wrinkled than the old skin. The new skin is also temporarily more sensitive to the sun. There are three basic types of chemical peels: – Superficial or Lunchtime Peel: Alpha-hydroxyl acid or another mild acid is used to penetrate only the outer layer of skin to gently exfoliate it. The treatment is used to improve the appearance of mild skin discoloration and rough skin as well as to refresh the face, neck, chest or hands.
                        </p>
                        <img src="img/areas_of_treatment/face-skin-clinic/chemical_peeling.jpg" alt="chemical peeling" />
                        <p>
                            <span>Medium Peel:</span> Glycolic or trichloroacetic acid is applied to penetrate the out and middle layers of skin to remove damaged skin cells. The treatment is used to improve age spots, fine lines and wrinkles, freckles and moderate skin discoloration. It also can be used to smooth rough skin and treat some precancerous skin growths, i.e. actinic keratosis.
                            <br></br>
                            <br></br>
                            <span>Deep Peel:</span> Tricholoracetic acid or phenol is applied to deeply penetrate the middle layer of skin to remove damaged skin cells. The treatment removes moderate lines, age spots, freckles and shallow scars. Patients will see a dramatic improvement in skin appearance. The procedure is used on the face and only can be performed once.
                            <br></br>
                            <br></br>
                            We also provide Peeling before wedding: A gentle peel that will leave you with a wedding ready complexion.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default BodyTreatmntList