import React, { useState } from 'react'
import '../../../styles/OurTechnology.css'

function OurTechnology() {
    const [activeSection, setActiveSection] = useState(null);
    const toggleAccordion = (section) => {
        setActiveSection(activeSection === section ? null : section)
    }
    return (
        <div className='OurTechnology'>
            <div className="container">
                {/* note: some of accordions are in wrong order.It was changed after some updates on contents */}
                <h2>Our Technology</h2>

                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(2)}>

                        <img src="img/our_technology/eLase.jpeg" className='dp-img' alt="diode laser" /><p>Diode Laser Hair Reduction</p><span className="icon">{activeSection === 2 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 2 ? 'active' : ''}`}>
                        <p>
                            Diode Laser hair removal is the answer to all your constant struggles in maintaining smooth, hair-free skin.
                            <br></br>
                            <br></br>
                            It is a cosmetic medical procedure. It uses a pulsating beam of light emitted by a laser machine. In fact, this beam of light energy helps to remove and get rid of unwanted hair.
                        </p>
                        <ul>
                            <h3>Types of Diode lasers:</h3>
                            <li>Triton Diolaze xl</li>
                            <li>eLase™</li>
                        </ul>
                        <p>
                            <span>a) Triton Diolaze XL: </span>
                            The world’s first patented multi-wavelength workstation, Triton is the only hair removal option that combines the three most popular wavelengths in one platform, using Fusion Technology, for permanent results on skin types up to VI.
                            <ul>
                                <span>Benefits:</span>
                                <li>In the past, lasers had to reduce peak power or increase pulse width to reduce the risk for postinflammatory hyperpigmentation (PIH) in darker skin. Now with Triton, higher power and a blend of wavelengths allows hair removal without compromising treatment parameters.</li>
                                <li>A broader range of patient concerns can be covered with this innovative, safe and effective platform.</li>
                            </ul>
                            <br></br>
                            <br></br>
                            <span>b) eLase™: </span>
                            eLase™ powered with Motif LHR™ is a pain free hair removal treatment that has the diode laser platform and delivers safe and effective treatments on the widest variety of skin and hair types.
                            <br></br>
                            <br></br>
                            The Motif LHR hair removal modes are both effective and comfortable with increased patient safety and reduced treatment times on All Skin Types. This is the first and only technology that uses combined energies to effectively and gently removes hair of many colours and types from all skin tones.
                            <br></br>
                            <br></br>
                            The NEW Motif IR applicator is a safer, more efficient way of treating superficial skin texture and pore size for overall facial rejuvenation with zero downtime.
                        </p>

                    </div>
                </div>

                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(5)}>

                        <img src="img/our_technology/Tribeam premium usfda.jpg" className='dp-img' alt="Tribeam premium" /><p>Q-Switched Nd:YAG-Tribeam Premium </p><span className="icon">{activeSection === 5 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 5 ? 'active' : ''}`}>
                        <p>Tribeam premium usfda approved q switched nd YAG laser for pigment reduction laser
                            toning. tattoo removal carbon peel birthmark removal freckles lentigines etc, p A carbon peel is a
                            revolutionary laser treatment that is completely painless with minimal-to-zero downtime. It is highly
                            beneficial for people with oily skin, blackheads, enlarged pores, dull skin, and acne on the face or
                            body. It is also an excellent way to exfoliate and refresh your skin, providing an instant refreshed
                            appearance and leaving skin feeling softer, smoother and firmer<br /><br />
                            TRI-BEAM Laser Toning is a cosmetic treatment that is revolutionizing skin care.
                            Considered the best method of skin lightening and skin rejuvenation, it is a
                            remarkable technology for treating multiple skin issues. Whether it is sun damage,
                            open pores, acne scars, hyperpigmentation, dark underarms, uneven complexion,
                            coarse skin texture or fine lines, TRI-BEAM Laser Toning is the answer to all skin
                            problems.</p>
                        <img src="img/DSC09969-min.jpg" alt="Tribeam" />

                        <img src="img/Pigment-reduction-laser-toning.jpg" alt="pigment reduction" />
                        <p>TRI-BEAM uses an advanced form of Q-Switched Nd: YAG laser that smoothens and
                            reverses skin issues. It uses light and heat energy emitted from a laser with the help
                            of carbon to vaporize the upper layers of skin that are damaged. The light beams
                            consistently break down the dark pigments (aka melanin) that are responsible for the
                            blemishes, pigmentation, scars, tan, etc. and reveals the underneath layers of your
                            skin. The laser also stimulates and improves collagen production thereby allowing a
                            healthy, fresh, and younger looking skin to shine through.</p>
                        <h3>What Is Laser Toning?</h3>
                        <p>
                            TRI-BEAM Laser Toning is a cosmetic treatment that is revolutionizing skin care.
                            Considered the best method of skin lightening and skin rejuvenation, it is a
                            remarkable technology for treating multiple skin issues. Whether it is sun damage,
                            open pores, acne scars, hyperpigmentation, dark underarms, uneven complexion,
                            coarse skin texture or fine lines, TRI-BEAM Laser Toning is the answer to all skin
                            problems.<br /><br />
                            TRI-BEAM uses an advanced form of Q-Switched Nd:YAG laser that smoothens and
                            reverses skin issues. It uses light and heat energy emitted from a laser with the help
                            of carbon to vaporize the upper layers of skin that are damaged. The light beams
                            consistently break down the dark pigments (aka melanin) that are responsible for the
                            blemishes, pigmentation, scars, tan, etc. and reveals the underneath layers of your
                            skin. The laser also stimulates and improves collagen production thereby allowing a
                            healthy, fresh, and younger looking skin to shine through.
                        </p>
                        <h3>What Are The Benefits Of Laser Toning
                            Treatment?</h3>
                        <p>Laser Toning using TRI-BEAM technology has greatly changed the course of skin
                            rejuvenation. It has also been proven to reduce fine lines and wrinkles, by inducing
                            collagen and improving the skin’s elasticity.
                            TRI-BEAM Laser Toning can reverse the damage and correct the effects of pollution,
                            age, stress, and sun.
                            Here are some additional benefits that will change your mind about TRI-BEAM Laser Toning</p>
                    </div>
                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(3)}>

                        <img src="img/our_technology/eCO2_FRACTIONAL.jpeg" className='dp-img' alt="eco2" /><p>eCO2 FRACTIONAL CO2 LASER</p><span className="icon">{activeSection === 3 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 3 ? 'active' : ''}`}>
                        <img src="img/DSC09957-min.jpg" alt="eCO2" />
                        <p>
                            eCO2 is the premier fractional ablative laser that delivers the full spectrum of resurfacing treatments to your busy practice.  Featured with Controlled Chaos Technology (CCT), eCO2 is an algorithm to pseudo-randomly deliver each microlaser beam and by placing maximum distance between sequential beams the system eliminates cross thermal diffusion between adjacent Micro-ablative Columns (MACs).<br /><br />

                            This allows the tissue to maximize heat dissipation between shots. The result is an increased safety profile with a reduction in postoperative discomfort and faster healing time leading to unparalleled fractional resurfacing results.
                        </p>
                        <h3>WHY ECO2</h3>
                        <ul>
                            <li>Patients are attracted to low risk, minimal downtime treatments</li>
                            <li>Controlled Chaos Technology creates pseudo-random microwound patterns to minimize heat build-up, minimize downtime, increase safety and maximize patient comfort.</li>
                            <li>Ergonomic and compact design.</li>
                            <li>Proven reliability.</li>
                            <li>Easily adjust treatment parameters through intuitive and user-friendly interface.</li>
                            <li>Capable of delivering fractional, full field ablative, incisional and excisional laser treatments.</li>
                            <li>Two energy delivery modes (dynamic and static) further increase versatility and control over outcomes.</li>
                        </ul>
                    </div>
                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(7)}>

                        <img src="img/our_technology/morpheus8.png" className='dp-img' alt="microneedling" /><p>MORPHEUS 8 - Microneedling Radio Frequency</p><span className="icon">{activeSection === 7 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 7 ? 'active' : ''}`}>
                        <img src="img/our_technology/morpheus8.png" alt="morpheus8" />
                        <p>
                            Radiofrequency microneedling is a cosmetic procedure. It uses tiny needles and radiofrequency waves to rejuvenate facial skin.
                            <br></br>
                            <br></br>
                            The treatment is a form of controlled skin injury. The damage stimulates the growth of healthy new skin, which can benefit common skin issues like acne scars and wrinkles.
                            <br></br>
                            <br></br>
                            Compared with treatments like chemical peels and dermabrasion, radiofrequency microneedling is minimally invasive. Read on to learn about the procedure, side effects, and benefits.
                            <br></br>
                            <br></br>
                        </p>
                        <p><span>Morpheus8</span> is a device that combines microneedling with radiofrequency technology for fractional resurfacing that stimulates collagen production. By targeting the deeper layers of the skin, the building blocks will reorganize themselves in a natural anti-aging process. There is little to no damage to the dermis and this procedure provides an extremely uniform effect.
                            <br></br>
                            <br></br>
                            Morpheus8 is a microneedling device that utilizes radiofrequency energy to remodel and contour the face and body via subdermal adipose remodeling. This fractional treatment penetrates deep into the skin and fat for a smoother and sleeker appearance. Morpheus8 can be combined with PRP for optimal results!</p>
                    </div>
                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(10)}>

                        <img src="img/DERMAPEN.jpeg" className='dp-img' alt="dermapen" /><p> DERMAPEN 4 - Microneedling </p><span className="icon">{activeSection === 10 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 10 ? 'active' : ''}`}>
                        <img src="img/DERMAPEN.jpeg" alt="dermapen" />
                        <p>
                            <span>Dermapen 4</span> is a microneedling pen that is faster than the other microneedling pens in the industry. It has a revolutionary 16-needle cartridge that helps it glide over the skin and has better results with less pain.
                            <br></br>
                            <br></br>
                            Dermapen 4 can be used to treat various skin problems like aging, scarring, stretch marks, acne, pigmentation, enlarged pores, burn contracture etc.
                            <br></br>
                            <br></br>
                            The latest Dermapen™ is taking the microneedling pen industry by storm.
                            <br></br>
                            <br></br>
                            Dermapen 4 glides over the skin creating millions of fine, vertical fractional channels up to 104% faster than other microneedling pens. These channels can carry up to 80% more topical nutrients deeper into the skin.
                            <br></br>
                            <br></br>
                            The micro-injuries trigger a natural process of repair, increasing the production of collagen and elastin and visibly improving a wide range of skin concerns.
                            <br></br>
                            <br></br>
                            Dermapen technology has significant benefits over dermal-rolling and fractional laser treatments, due to the minimal trauma to your patient’s skin. Dermapen microneedling gives skin the chance to spend more effort fighting the ailment itself, rather than any collateral damage caused by the procedure.
                        </p>

                    </div>
                </div>

                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(0)}>

                        <img src="img/our_technology/Ultracel-Q-Plus.jpg" className='dp-img' alt="ultracel q" /><p>HIFU - Ultracel Q+ Dot & linear</p><span className="icon">{activeSection === 0 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 0 ? 'active' : ''}`}>
                        <img src="img/IMG_9937-min.jpg" alt="ultracel" />
                        <p>
                            ULTRAcel Q+ (ULTRAcel Q Plus) is a HIFU medical equipment (High Intensity Focused Ultrasound) that has been introduced not only in its home country (Korea) but also in Asian countries such as Japan, Taiwan, Singapore, and Malaysia, as well as in countries around the world including the Middle East, Europe, and South America(e.g., over 700 Japanese physicians have installed it)*. While most people’s perception of HIFU is limited to Sagging Medication, ULTRAcel Q+ is the world’s first and only linear-shot HIFU medical equipment that can dissolve fat from double chins, upper arms, and other parts of the body.
                            <br></br>
                            <br></br>
                            The market for the sagging treatment with new HIFU technology equipment is expanding. The treatment meets the needs of patients who do not prefer invasive treatments (surgery), at the same time it is also the treatment of choice for patients new to cosmetic medicine. Careful diagnosis and counseling for non-invasive(HIFU)treatment is just as important as, sometimes even more than those for invasive treatment to build trust with every patient.　ULTRAcel Q+ has developed a cartridge that achieves 300 shots in 8 minutes, providing the fastest treatment in the industry, without compromising the level of effect immediately after treatment.
                            Shorter treatment time allows both clinics and patients to have communication before and after treatment. This contributes to overall patient satisfaction, which is to build an ongoing relationship.
                        </p>
                        <a class="resume" href="files/Ultracel Q+ Dot & linear.pdf" target="_blank" download><i
                            class="fa-solid fa-download"></i>Download Brochure</a>
                    </div>
                </div>

                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(8)}>

                        <img src="img/our_technology/Cooltech.jpg" className='dp-img' alt="microneedling" /><p>CoolTech - Non Surgical Fat Reduction (Cryolipolysis)</p><span className="icon">{activeSection === 8 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 8 ? 'active' : ''}`}>
                        <img src="img/IMG_9935-min.jpg" alt="cooltech" />

                        <p>
                            Cooltech uses cryolipolysis which is controlled cooling technology to safely and effectively freeze unwanted fat cells. A vacuum applicator is applied to the area which holds it between cooling panels at a set temperature for up to 70 minutes. This triggers a process called Apoptosis and your body takes care of it from there. In the months following your treatment those unwanted fat cells are slowly absorbed and naturally processed through the lymphatic system.  As this process takes a few months to reach completion the skin in the area will tighten like it would if you were to lose weight naturally. Therefore, there is far less risk of loose skin than there is with liposuction.For more information, download and view the brochure.
                        </p>
                        <a class="resume" href="files/Cooltech.pdf" target="_blank" download><i
                            class="fa-solid fa-download"></i>Download Brochure</a>

                    </div>
                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(1)}>

                        <img src="img/our_technology/mesojet.jpeg" className='dp-img' alt="mesojet" /><p>MesoJet</p><span className="icon">{activeSection === 1 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 1 ? 'active' : ''}`}>
                        <img src="img/DSC09981-min.jpg" alt="MesoJetTM" />
                        <p>
                            MesoJetTM is the result of our long lasting experience with the main hydroporation technologies on the
                            market and as producers of professional cosmetic serums devoted to hydrodynamic procedures.
                            MesoJetTM is engineered to provide immediate and long lasting medical aesthetic results through
                            procedures that are 100% safe and extremely enjoyable at the same time.
                            Specific products and programs allow to customize and control each procedure at all stages, offering
                            always a pampering treatment experience.To know more about it, please read the brochure below.
                        </p>
                        <a class="resume" href="files/Meso Jet From Spamed.pdf" target="_blank" download><i
                            class="fa-solid fa-download"></i>Download Brochure</a>
                    </div>
                </div>


                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(9)}>

                        <img src="img/our_technology/Synergy-New-Image-1080-×-1080px.png" className='dp-img' alt="synergy" /><p>AquaFacial - Tubelite Synergy</p><span className="icon">{activeSection === 9 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 9 ? 'active' : ''}`}>
                        <p>TUBELiTE Synergy+ is a Multi-Applicator Skin Management Platform, incorporated with Four Novel Technologies:
                            <br></br>
                            <br></br>
                            TUBELiTE Synergy+ is an advanced medi-facial device having a superior advantage over conventional Microdermabrasion since it has 4 novel technologies in one device.</p>

                        <ul>
                            <p>Benefits of using Tubelite Synergy+ is listed below:</p>
                            <li>Deep cleansing and removal of dead impurity</li>
                            <li> Black and white head removal</li>
                            <li>Skin brightening</li>
                            <li>More radiant and supple skin</li>
                            <li>Improved skin texture</li>
                            <li>Enhances skin tone</li>
                            <li>Skin rejuvenation</li>
                            <li>Nourishing and hydrating skin</li>
                            <li>Correction of fine lines and wrinkles</li>
                        </ul>
                    </div>
                </div>

                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(4)}>

                        <img src="img/our_technology/UM-150A Radiofrequency Ablation.jpg" className='dp-img' alt="UM-150A Radiofrequency" /><p>UM-150A Radiofrequency Ablation</p><span className="icon">{activeSection === 4 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 4 ? 'active' : ''}`}>
                        <p>
                            Radiofrequency ablation is a versatile dermatological procedure used for surgical management of various skin growth and lesions i.e. Wart, corn, mole, skin tag, and tumour.<br /><br />



                            Radiofrequency ablation (RFA) has gained importance in the recent years as it is highly effective in the cutting of the skin lesions with adequate hemostasis at the same time because of good coagulation. RFA is done with UM150A RADIO FREQUENCY SURGICAL UNIT. This unit adopts 4.8MHz Radio Frequency in order to have the optional surgery results of minimal bleeding, minimizing thermal damage to tissues.<br /><br />

                            Radiofrequency can be used for incisional techniques that produce full-thickness excision of nevi, shave techniques that produce partial-thickness removal of superficial lesions and removing vascular lesions such as hemangiomas or pyogenic granulomas.<br /><br />

                            RFA is a simple, safe procedure with wider applications. It causes less lateral heat spread and tissue damage and provides better control in comparison to electric cautery.<br /><br />

                            As hemostasis occurs simultaneously, the time required for the surgery is less. There are fewer side effects and complications. RFA can be easily combined with other surgical modalities such as cryotherapy and laser for treatment.
                        </p>
                    </div>
                </div>
                <div className="accordion-section" data-aos="fade-up" data-aos-duration="1000">
                    <button className="accordion" onClick={() => toggleAccordion(11)}>

                        <img src="img/FORMA.webp" className='dp-img' alt="forma" /><p>FORMA - Radiofrequency Skin Tightening </p><span className="icon">{activeSection === 11 ? (<i class="fa-solid fa-xmark"></i>) : (<i class="fa-solid fa-plus"></i>)}</span>

                    </button>

                    <div className={`panel ${activeSection == 11 ? 'active' : ''}`}>
                        <img src="img/FORMA.webp" alt="forma" />
                        <img src="img/DSC09955-min.jpg" alt="forma" />
                        <p>
                            Forma is a radio frequency skin tightening treatment. It is a non-invasive procedure that uses heat and radiofrequency to increase collagen production both in the face and body resulting in lifting, tightening, and contouring your skin, almost like ironing your skin – in turn helping reduce the appearance of fine lines and wrinkles – and aiding recovery from age spots and hyperpigmentation.
                            <br></br>
                            <br></br>
                            Forma treats loose skin by targeting the deep layers of the skin with no area being under or over treated, as it has constant skin thermal temperature monitoring with an automatic cutoff feature. This ensures maximum and consistent results.
                            <br></br>
                            <br></br>
                            Forma is great treatment for all skin types both light and dark. Loose lax skin is one of the most common cosmetic complaints of my patients. Forma improves skin’s appearance with smoother skin, reduction of wrinkles and a more radiant glow.
                            Forma can be used on both the face and body. Commonly treated areas on the face include: forehead, crow’s feet, upper and lower eyelids, nasolabial folds, jowls, smile lines, and neck. On the body, popular treatment areas include the arms (sagging skin), abdomen and any area that has sagging skin.
                            Forma is an essentially painless procedure with no downtime due to its precise technology with mild redness of the skin post procedure that lasts a few hours.
                        </p>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default OurTechnology