import React from 'react';
import '../../../styles/Body.css'
import { Link } from 'react-router-dom';

function Body() {
    return (
        <div className='Body'>
            <div className="container">
                <div className="row">
                    <div className="left-col">
                        <div className="img-container">
                            <img src="img/areas_of_treatment/body/body-img.jpg" alt="body" />
                        </div>
                    </div>
                    <div className="right-col">
                        <div className="content">
                            <h2>Body</h2>
                            <p>Many people dislike their bodies and come to Prime worried about things such as stretch marks, scars, cellulite, bingo wings, skin Lesions and skin tags, to name but a few. Our team of medical experts are here to help you to be happy in your own skin.</p>
                            <Link to="/body">Read More</Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Body