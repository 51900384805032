import React from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import BannerData from '../Components/Helpers/BannerData'
import FaceIntro from '../Components/FaceSkinClinicPage/FaceIntro/FaceIntro'
import FaceTreatmntList from '../Components/FaceSkinClinicPage/FaceTreatmntList/FaceTreatmntList'
import Footer from '../Components/General_cmpnts/Footer/Footer'
import { Helmet } from 'react-helmet'

function FaceSkinClinic() {
  const { FaceSkinClinicH2, FaceSkinClinicImg } = BannerData

  return (
    <div className='FaceSkinClinic'>
      <Helmet>
        <title>Best Skin Clinic in calicut | Skin Speacialist in Calicut Kerala Prime</title>
        <meta name="description" content="Best Skin clinic in Calicut with 100% Satisfaction. Prime Skin Clinic offers various services for Skincare treatments, Laser hair removal, Hair transplant best skin care from experienced skin specialists"></meta>
        <link rel="canonical" href="https://www.primeskinclinic.in/face-skin-clinic/"></link>
        <meta property="og:locale" content="en_GB"></meta>
        <meta property="og:type" content="article"></meta>
        <meta property="og:title" content="Best Skin Clinic in calicut | Skin Speacialist in Calicut Kerala Prime"></meta>
        <meta property="og:description" content="Best Skin clinic in Calicut with 100% Satisfaction. Prime Skin Clinic offers various services for Skincare treatments, Laser hair removal, Hair transplant best skin care from experienced skin specialists"></meta>
        <meta property="og:url" content="https://www.primeskinclinic.in/face-skin-clinic/"></meta>
        <meta property="og:site_name" content="Prime Skin, Hair &amp; Laser Clinic"></meta>
        <meta property="og:image" content="img/DSC09976[1]-min.JPG" />

      </Helmet>
      <Navbar />
      <BannerSection h2={FaceSkinClinicH2} imgUrl={FaceSkinClinicImg} />
      <FaceIntro />
      <FaceTreatmntList />
      <Footer />

    </div>
  )
}

export default FaceSkinClinic