import React from 'react'
import '../../../styles/Values.css'

function Values() {
    return (
        <div className='Values'>
            <h2>Our Values</h2>
            <div className="container">
                <div className="row">
                    <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-delay="100">
                        <img src="img/values/expert.png" alt="our mission" />
                        <h3>PROFESSIONAL</h3>
                        <p>All of our procedures and treatments are patient-centred, effective, efficient, fair and timely. We use extremely high standard machines and equipments which ensure we offer the best quality of professionalism and care throughout the patient’s journey with us. And we make sure our patients receive the best care following their treatments.</p>
                    </div>
                    <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-delay="100">
                        <img src="img/values/motivation.png" alt="our mission" />
                        <h3>COURAGE</h3>
                        <p>Doing what is right for our patients without question. Patient well-being is always our first priority, and if a procedure or treatment is not required we will always let them know.</p>

                    </div>
                    <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-delay="100">
                        <img src="img/values/commitment.png" alt="our mission" />
                        <h3>COMMITMENT</h3>
                        <p>Unwavering in our quest for exceptional quality and service. We put our hearts into everything we do. Our patients are realistic in their choices, so we maintain a level of responsibility and reliability.  We will always ensure that the patient fully understands any risks or limitations prior to undergoing a treatment.</p>

                    </div>
                    <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-delay="100">
                        <img src="img/values/caring.png" alt="our mission" />
                        <h3>CARING</h3>
                        <p>We offer the best standard of care throughout a patient’s journey at Prime.  We stand here for our patients, to support each person every step of the way.</p>

                    </div>
                    <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-delay="100">
                        <img src="img/values/idea.png" alt="our mission" />
                        <h3>CURIOSITY</h3>
                        <p>Fostering creativity and innovation in our pursuit of excellence.
Ultimately, our success is driven by our commitment to ensure that every patient is happy and would recommend us as a reliable, trusted and quality provider.</p>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default Values