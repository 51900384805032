import React from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import BannerData from '../Components/Helpers/BannerData'
import Profile from '../Components/AboutPage/Profile/Profile'
import Values from '../Components/AboutPage/Values/Values'
import Team from '../Components/AboutPage/Team/Team'
import OurTechnology from '../Components/AboutPage/OurTechnology/OurTechnology'
import Footer from '../Components/General_cmpnts/Footer/Footer'
import { Helmet } from 'react-helmet'

function About() {
  const { AboutH2, AboutImg } = BannerData
  return (
    <div className='About'>
      <Helmet>
        <title>About - Prime Skin, Hair &amp; Laser Clinic</title>
        <link rel="canonical" href="https://www.primeskinclinic.in/about/"></link>
        <meta property="og:locale" content="en_GB"></meta>
        <meta property="og:type" content="article"></meta>
        <meta property="og:title" content="About - Prime Skin, Hair &amp; Laser Clinic"></meta>
        <meta property="og:url" content="https://www.primeskinclinic.in/about/"></meta>
        <meta property="og:site_name" content="Prime Skin, Hair &amp; Laser Clinic"></meta>
        <meta property="og:image" content="img/pexels-photo-3373721.webp" />

      </Helmet>
      <Navbar />
      <BannerSection h2={AboutH2} imgUrl={AboutImg} />
      <Profile />
      <Values />
      <Team />
      <OurTechnology />
      <Footer />

    </div>
  )
}

export default About