import React from 'react';
import '../../../styles/BodyIntro.css';

function BodyIntro() {
    return (
        <div className='BodyIntro'>
            <div className="container">
                <div className="row">
                    <div className="left-col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000" data-aos-offset="0" >
                        <h2>Body</h2>
                        <p>Many people dislike their bodies and come to Prime worried about things such as stretch marks, scars, cellulite, bingo wings, skin Lesions and skin tags, to name but a few. Our team of medical experts are here to help you to be happy in your own skin.</p>
                    </div>
                    <div className="right-col custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000" >
                        <div className="img-container">
                            <img src="img/areas_of_treatment/body/body_intro.jpg" alt="body intro" />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BodyIntro