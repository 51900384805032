import React, { useEffect, useRef, useState } from 'react';
import '../../../../styles/PatientsCounter.css';
import CountUp from 'react-countup';

const CounterCard = ({ end, duration,aosDelay,title,icon }) => {
    const [isVisible, setIsVisible] = useState(false);
    const counterRef = useRef();

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threeshold: 0.5,
        };
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            })
        }

        const observer = new IntersectionObserver(handleIntersection, options);
        observer.observe(counterRef.current);

        return () => {
            observer.disconnect();
        }
    }, [])

    return (
        <div className="counter-card" ref={counterRef}data-aos="fade-down"data-aos-duration="1000"data-aos-delay={aosDelay}>
            <i className={icon}></i>
            <h3>{title}</h3>
            {isVisible && <CountUp end={end} duration={duration} />}<span>+</span>
        </div>
    )
}

function PatientsCounter() {
    return (
        <div className='PatientsCounter'>
            <div className="counter-container">
                <div className="counter-row">
                    <CounterCard end={20} duration={3} aosDelay={100}title={'Years of experience'}icon={'fa-solid fa-medal'} />
                    <CounterCard end={34685} duration={3} aosDelay={200}title={'Total number of Patients'}icon={'fa-solid fa-hospital-user'}/>
                    <CounterCard end={19200} duration={3} aosDelay={300}title={'Treatments'}icon={'fa-solid fa-hand-holding-medical'}/>
                    <CounterCard end={30} duration={3} aosDelay={400}title={'Staff Members'}icon={'fa-solid fa-user-doctor'}/>
                </div>
            </div>
        </div>
    )
}

export default PatientsCounter