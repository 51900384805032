import React from 'react'
import '../../../styles/ShareButton.css'
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    VKShareButton,
    WhatsappShareButton,
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share";

function ShareButton() {
    const shareUrl = window.location.href;
    // console.log(shareUrl);
    return (
        <div className='ShareButton'>
            <h2>Share Via</h2>
            <EmailShareButton url={shareUrl} >
                <EmailIcon size={32} round={true} />
            </EmailShareButton>
            <FacebookShareButton url={shareUrl} >
                <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <WhatsappShareButton url={shareUrl} >
                <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
            <LinkedinShareButton url={shareUrl} >
                <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
            <PinterestShareButton url={shareUrl} >
                <PinterestIcon size={32} round={true} />
            </PinterestShareButton>
            <TelegramShareButton url={shareUrl} >
                <TelegramIcon size={32} round={true} />
            </TelegramShareButton>
            <RedditShareButton url={shareUrl} >
                <RedditIcon size={32} round={true} />
            </RedditShareButton>
            <TumblrShareButton url={shareUrl} >
                <TumblrIcon size={32} round={true} />
            </TumblrShareButton>
            <TwitterShareButton url={shareUrl} >
                <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <VKShareButton url={shareUrl} >
                <VKIcon size={32} round={true} />
            </VKShareButton>
           

        </div>
    )
}

export default ShareButton