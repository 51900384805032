import React, { useEffect, useState } from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import BannerData from '../Components/Helpers/BannerData';
import axios from 'axios';
import { wordpressUrl } from '../Components/Helpers/wordpressUrl';
import GalleryList from '../Components/GalleryPage/GalleryList/GalleryList';
import EmbedList from '../Components/GalleryPage/EmbedList/EmbedList';
import Footer from '../Components/General_cmpnts/Footer/Footer';
import { Helmet } from 'react-helmet';



function Gallery() {
    const { GalleryH2, GalleryImg } = BannerData;
    const [gallerydata, setgallery] = useState([]);

    useEffect(() => {

        const id = 29
        axios.get(`${wordpressUrl}wp-json/wp/v2/gallery/${id}`)
            .then(res => {
                setgallery(res.data)
                // console.log(res.data + 'gallery array')
            }).catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <div className='Gallery'>
            <Helmet>
                <title>Gallery - Prime Skin, Hair &amp; Laser Clinic</title>
                <link rel="canonical" href="https://www.primeskinclinic.in/gallery/"></link>
                <meta property="og:locale" content="en_GB"></meta>
                <meta property="og:type" content="article"></meta>
                <meta property="og:title" content="Gallery - Prime Skin, Hair &amp; Laser Clinic"></meta>
                <meta property="og:url" content="https://www.primeskinclinic.in/gallery/"></meta>
                <meta property="og:site_name" content="Prime Skin, Hair &amp; Laser Clinic"></meta>
                <meta property="og:image" content="img/DSC09976[1]-min.JPG" />

            </Helmet>
            <Navbar />
            <BannerSection h2={GalleryH2} imgUrl={GalleryImg} />
            <GalleryList gallery={gallerydata} />
            <EmbedList embed={gallerydata} />
            <Footer />

        </div>
    )
}

export default Gallery