import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../../styles/BlogList.css';
import { Link } from 'react-router-dom';
import { wordpressUrl } from '../../Helpers/wordpressUrl';

function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    const [month, year] = formattedDate.split(' ');
    return `${month}, ${year}`;
}

function BlogList() {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);
    const [fetchedBlogIds, setFetchedBlogIds] = useState(new Set());
    const [page, setPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [filteredArray, setFilteredArray] = useState([]);

    const perPage = 100;
    const lastPageIndex = page * perPage;

    const fetchBlogs = () => {
        axios.get(`${wordpressUrl}wp-json/wp/v2/blog?per_page=${perPage}&page=${page}`)
            .then((res) => {
                const newBlogs = res.data.filter((blog) => !fetchedBlogIds.has(blog.id));
                setBlogs((prevBlogs) => [...prevBlogs, ...newBlogs]);
                setFilteredArray((prevBlogs) => [...prevBlogs, ...newBlogs]);
                newBlogs.forEach((blog) => fetchedBlogIds.add(blog.id));
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchBlogs();
    }, [page]);

    const filterByCategory = (category) => {
        if (category === 'All') {
            setFilteredArray(blogs);
        } else {
            const filtered = blogs.filter(blg => blg.acf.category === category);
            setFilteredArray(filtered);
        }
        setSelectedCategory(category);
    };

    const handleLoadMore = () => {
        setPage((prevPage) => prevPage + 1);
        fetchBlogs(); // Fetch new blogs when the page button is clicked
    };

    if (loading) {
        return (
            <div className="spinning_loading">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }



    return (
        <div className='BlogList'>
            <div className="category-buttons">
                <button onClick={() => filterByCategory('All')} className={selectedCategory === 'All' ? 'active' : 'not-active'}>All</button>
                <button onClick={() => filterByCategory('Face')} className={selectedCategory === 'Face' ? 'active' : 'not-active'}>Face</button>
                <button onClick={() => filterByCategory('Hair')} className={selectedCategory === 'Hair' ? 'active' : 'not-active'}>Hair</button>
                <button onClick={() => filterByCategory('Body')} className={selectedCategory === 'Body' ? 'active' : 'not-active'}>Body</button>
                <button onClick={() => filterByCategory('Others')} className={selectedCategory === 'Others' ? 'active' : 'not-active'}>Others</button>
            </div>
            <div className="container">
                <div className="row">
                    {filteredArray.length === 0 ? (
                        <div className="no-blogs-message">
                            <h3>No Blogs in this category</h3>
                        </div>
                    ) : (
                        filteredArray.map(blog => (
                            <div className="card" key={blog.id}>
                                <Link to={`/blogs/${blog.slug}`}>
                                    <div className="img-container">
                                        {blog.acf.cover_image &&
                                            <img src={blog.acf.cover_image.sizes.medium} alt={blog.acf.name} />
                                        }
                                    </div>
                                    <div className="content">
                                        {blog.title.rendered && <Link to={`/blogs/${blog.slug}`}>{blog.title.rendered}</Link>}
                                        <div className="bottom-content">
                                            {blog.acf && blog.acf.editor_image ? (
                                                <img src={blog.acf.editor_image.sizes.thumbnail} className='editor-dp' alt="editor dp" />
                                            ) : (
                                                <img src="https://as1.ftcdn.net/v2/jpg/03/53/11/00/1000_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg" className='editor-dp' alt="default dp" />
                                            )}
                                            <p>{blog.acf.edited_by} | {formatDate(blog.modified)}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    )}
                </div>
                <div className="load_more">
                    {filteredArray.length === lastPageIndex && <button className='loadMore_btn' onClick={handleLoadMore}>Load More</button>}
                </div>
            </div>
        </div>
    );
}

export default BlogList;
