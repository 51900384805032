import React from 'react'
import '../../../styles/Profile.css'

function Profile() {
    return (
        <div className='Profile'>
            <div className="container">
                <div className="row">
                    <div className="left-col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000">
                        <h2>Welcome to Prime Skin clinic</h2>
                        <p>
                            Beauty and body care go hand in hand, especially for those who want to show off their best with confidence. At Dr Roshni’s Prime skin clinic, we understand that the cosmetic treatments are as much about helping you to achieve a greater self-confidence and to maintain a vibrant youthfulness.<br /><br />

                            Prime Skin Clinic offers a variety of specialized non – surgical cosmetic procedures and specialized medicines to enhance and improve your skin health. We stand to provide a positive, supportive environment, where your questions and concerns are always addressed to your satisfaction. Our mission is to deliver results that make clients look and feel their best while enjoying their time in our welcoming and warm environment.<br /><br />

                            We pride ourselves on our professionalism and passion for achieving client satisfaction.
                        </p>
                    </div>
                    <div className="right-col custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000">
                        <div className="img-container">
                            <img src="img/DSC09942[1]-min - Copy.jpg" alt="profile" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Profile