import React from 'react'
import '../../../styles/Face.css'
import { Link } from 'react-router-dom'

function Face() {
    return (
        <div className='Face'>
            <div className="container">
                <div className="row">
                    <div className="left-col">
                        <div className="content">
                            <h2>Face</h2>
                            <p>You may be worried about fine lines, wrinkles, sagging skin, hyperpigmentation, dark circles, acne spots, etc. Our team of medical experts are here with excellent solutions on skincare or problem areas.</p>
                            <Link to="/face-skin-clinic">Read More</Link>
                        </div>
                    </div>
                    <div className="right-col">
                        <div className="img-container">
                            <img src="img/face_intro.jpg" alt="face intro" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Face